import {IChatMessage, IChatMessageData, IChatMessageReaction, IChatMessageThread} from "@buildwithflux/models";
import {ChatRepository} from "@buildwithflux/repositories";
import {Logger} from "@buildwithflux/shared";
import {keys, omitBy, values} from "lodash";
import {uuid} from "short-uuid";

import {CurrentUserService} from "../../../../../../../../../../modules/auth";
import {CopilotChatStoreState} from "../useCopilotChatStore";

export function setCurrentThreadToLatest(
    set: (fn: (state: CopilotChatStoreState) => void) => void,
    get: () => CopilotChatStoreState,
) {
    return () => {
        const firstThreadUid = values(get().threads).sort((a, b) => b.updated_at - a.updated_at)[0]?.uid;

        if (firstThreadUid) {
            get().setCurrentThread(firstThreadUid);
        } else {
            get().setCurrentThread("");
        }
    };
}

function addAddedOrUpdatedMessages(
    set: (fn: (state: CopilotChatStoreState) => void) => void,
    threadUid: string,
    addedOrChangedMessages: IChatMessageData[],
) {
    set((state) => {
        const thread = state.threads[threadUid];

        if (!thread) return;

        return {
            ...state,
            threadLoaded: true,
            threads: {
                ...state.threads,
                [threadUid]: {
                    ...thread,
                    messages: {
                        ...thread.messages,
                        ...addedOrChangedMessages.reduce(
                            (
                                acc: {
                                    [messageUid: string]: IChatMessage;
                                },
                                messageData,
                            ) => {
                                acc[messageData.uid] = {
                                    ...messageData,
                                    reactions: {},
                                };

                                return acc;
                            },
                            {},
                        ),
                    },
                },
            },
        };
    });
}

function subscribeToMessageReactions(
    set: (fn: (state: CopilotChatStoreState) => void) => void,
    get: () => CopilotChatStoreState,
    currentDocumentUid: string,
    threadUid: string,
    addedOrChangedMessages: IChatMessageData[],
    chatRepository: ChatRepository,
    logger: Logger,
) {
    values(addedOrChangedMessages).forEach((message) => {
        const messageReactionsSubscription = get().threadMessagesReactionsSubscription[message.uid];

        if (messageReactionsSubscription) {
            messageReactionsSubscription();
        }

        const subscription = chatRepository.subscribeToMessageReactions(
            currentDocumentUid,
            threadUid,
            message.uid,
            (addedOrChangedReactions) => {
                // On Receive
                set((state) => {
                    const thread = state.threads[threadUid];
                    const messageState = thread?.messages[message.uid];

                    if (!thread || !messageState) return state;

                    return {
                        ...state,
                        threads: {
                            ...state.threads,
                            [threadUid]: {
                                ...thread,
                                messages: {
                                    ...thread.messages,
                                    [message.uid]: {
                                        ...messageState,
                                        reactions: {
                                            ...messageState.reactions,
                                            ...addedOrChangedReactions.reduce(
                                                (
                                                    acc: {
                                                        [reactionUid: string]: IChatMessageReaction;
                                                    },
                                                    reactionData,
                                                ) => {
                                                    acc[reactionData.uid] = reactionData;

                                                    return acc;
                                                },
                                                {},
                                            ),
                                        },
                                    },
                                },
                            },
                        },
                    };
                });
            },
            (removedReactions) => {
                // On Delete
                set((state) => {
                    const uidsToRemove = keys(removedReactions);
                    const thread = state.threads[threadUid];
                    const messageState = thread?.messages[message.uid];

                    if (!thread || !messageState) return state;

                    return {
                        ...state,
                        threads: {
                            ...state.threads,
                            [threadUid]: {
                                ...thread,
                                messages: {
                                    ...thread.messages,
                                    [message.uid]: {
                                        ...messageState,
                                        reactions: omitBy(messageState.reactions, (value) =>
                                            uidsToRemove.includes(value.uid),
                                        ),
                                    },
                                },
                            },
                        },
                    };
                });
            },
            (error) => {
                logger.error("Error subscribing to chat message reactions", error);
            },
        );

        set((state) => {
            return {
                ...state,
                threadMessagesReactionsSubscription: {
                    ...state.threadMessagesReactionsSubscription,
                    [message.uid]: subscription,
                },
            };
        });
    });
}

function onAddedOrUpdatedMessages(
    set: (fn: (state: CopilotChatStoreState) => void) => void,
    get: () => CopilotChatStoreState,
    threadUid: string,
    chatRepository: ChatRepository,
    currentDocumentUid: string,
    logger: Logger,
) {
    return (addedOrChangedMessages: IChatMessageData[]) => {
        // On Receive
        addAddedOrUpdatedMessages(set, threadUid, addedOrChangedMessages);

        subscribeToMessageReactions(
            set,
            get,
            currentDocumentUid,
            threadUid,
            addedOrChangedMessages,
            chatRepository,
            logger,
        );
    };
}

function onRemovedMessages(
    set: (fn: (state: CopilotChatStoreState) => void) => void,
    get: () => CopilotChatStoreState,
    threadUid: string,
) {
    return (removedMessages: IChatMessageData[]) => {
        // On Delete
        set((state) => {
            const uidsToRemove = keys(removedMessages);

            const thread = state.threads[threadUid];

            if (!thread) return state;

            return {
                ...state,
                threadLoaded: true,
                threads: {
                    ...state.threads,
                    [threadUid]: {
                        ...thread,
                        messages: omitBy(thread.messages, (value) => uidsToRemove.includes(value.uid)),
                    },
                },
            };
        });

        // remove reaction subscriptions
        values(removedMessages).forEach((message) => {
            const messageReactionsSubscription = get().threadMessagesReactionsSubscription[message.uid];

            if (messageReactionsSubscription) {
                messageReactionsSubscription();
            }
        });
    };
}

export function setCurrentThread(
    set: (fn: (state: CopilotChatStoreState) => void) => void,
    get: () => CopilotChatStoreState,
    chatRepository: ChatRepository,
    logger: Logger,
) {
    return (threadUid: string) => {
        const {currentDocumentUid} = get();
        if (!currentDocumentUid) return;

        set((draft) => {
            draft.threadLoaded = false;
            draft.currentThreadUid = threadUid;

            if (!draft.currentThreadUid) return;

            if (draft.threadMessagesSubscription) {
                draft.threadMessagesSubscription();
            }

            draft.threadMessagesSubscription = chatRepository.subscribeToThreadMessages(
                currentDocumentUid,
                threadUid,
                onAddedOrUpdatedMessages(set, get, threadUid, chatRepository, currentDocumentUid, logger),
                onRemovedMessages(set, get, threadUid),
                (error) => {
                    logger.error("Error subscribing to chat messages", error);
                },
            );
        });
    };
}

export function createThread(
    set: (fn: (state: CopilotChatStoreState) => void) => void,
    get: () => CopilotChatStoreState,
    currentUserService: CurrentUserService,
    chatRepository: ChatRepository,
    logger: Logger,
) {
    return async () => {
        const {currentDocumentUid, currentThreadUid: originalCurrentThreadUid} = get();

        if (!currentDocumentUid) return;

        const currentUser = currentUserService.getCurrentUser();
        if (!currentUser) return;

        // Generate a new thread UID and construct the thread object
        const threadUid = uuid();
        const timestamp = Date.now();

        const newThread: IChatMessageThread = {
            uid: threadUid,
            owner_uid: currentUser.uid,
            owner_handle: currentUser.handle,
            messages: {},
            created_at: timestamp,
            updated_at: timestamp,
        };

        // Optimistically update the state
        set((draft) => {
            draft.threads[threadUid] = newThread;
        });

        get().setCurrentThread(threadUid);

        try {
            // Persist the new thread to the backend
            await chatRepository.createThread(currentDocumentUid, newThread);
        } catch (error) {
            logger.error("Failed to create thread:", error);

            // Rollback optimistic update in case of an error
            set((draft) => {
                delete draft.threads[threadUid]; // Remove the thread
                if (draft.currentThreadUid === threadUid) {
                    draft.currentThreadUid = originalCurrentThreadUid; // Reset current thread UID if it was the new one
                }
            });
        }
    };
}

export function updateThreadName(
    set: (fn: (state: CopilotChatStoreState) => void) => void,
    get: () => CopilotChatStoreState,
    chatRepository: ChatRepository,
    logger: Logger,
) {
    return async (threadUid: string, name: string) => {
        const {currentDocumentUid, threads} = get();

        const originalThread = threads[threadUid]; // Original thread for rollback

        // Early exit if prerequisites are not met
        if (!currentDocumentUid || !originalThread) return;

        // Optimistically update the UI
        set((draft) => {
            const draftThread = draft.threads[threadUid];
            if (draftThread) {
                draftThread.name = name;
                draftThread.updated_at = Date.now();
            }
        });

        try {
            // Persist the updated thread to the backend
            await chatRepository.setThread(currentDocumentUid, {
                ...originalThread,
                name,
                updated_at: Date.now(),
            });
        } catch (error) {
            logger.error("Failed to update thread name:", error);

            // Rollback the optimistic update on failure
            set((draft) => {
                const draftThread = draft.threads[threadUid];
                if (draftThread) {
                    draftThread.name = originalThread.name; // Revert to the original name
                    draftThread.updated_at = originalThread.updated_at; // Revert to the original timestamp
                }
            });
        }
    };
}

export function deleteThread(
    set: (fn: (state: CopilotChatStoreState) => void) => void,
    get: () => CopilotChatStoreState,
    chatRepository: ChatRepository,
    logger: Logger,
) {
    return async (threadUid: string) => {
        const {currentDocumentUid, threads} = get();

        // Store a backup of the thread for rollback
        const deletedThread = threads[threadUid];

        // Early exit if prerequisites are not met
        if (!currentDocumentUid || !deletedThread) return;

        // Optimistically update the state
        set((draft) => {
            delete draft.threads[threadUid];
        });

        get().setCurrentThreadToLatest();

        try {
            // Persist the deletion to the backend
            await chatRepository.deleteThread(currentDocumentUid, threadUid);
        } catch (error) {
            logger.error("Failed to delete thread:", error);

            // Rollback optimistic update on failure
            set((draft) => {
                if (deletedThread) {
                    draft.threads[threadUid] = deletedThread;

                    // Optionally, reset the current thread UID if the deleted thread was the current one
                    if (!draft.currentThreadUid) {
                        draft.currentThreadUid = threadUid;
                    }
                }
            });
        }
    };
}
