/*
 * DISCLAIMER: Merott created this, but Matthias approved! 😅
 *
 * Seriously, this file is here to help us *contain* the hacks and workarounds used within the project,
 * and to serve as a constant reminder to get rid of them as soon as possible. More importantly, it's NOT here to
 * encourage more hacks and band-aids in the project!
 *
 * Keep functions as dumb as possible (no sophisticated hacks with lots of parameters), and always add comments
 * to explain why they're here. This file does not need to follow DRY principles because the more isolated each
 * hack, the fewer pieces of code will depend on it, and the sooner we can get rid of it!
 */

/*
 * Temporary solution until we address this: https://buildwithflux-.workplace.com/groups/699861091194038 // Not sure how to archive
 * If we stop truncating radians, this should not be necessary anymore (theoretically 😅)
 */
export function fixAngleDecimals(angle: number, maxDecimals = 2) {
    return Number(angle.toFixed(maxDecimals));
}
