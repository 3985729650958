/* eslint-disable @typescript-eslint/member-ordering */
import {headVersionName} from "@buildwithflux/constants";
import {
    DEFAULT_GROUND_TERMINAL_PART_UID,
    ElementHelper,
    GENESIS_TERMINAL_PART_UID,
    get,
    GROUND_PORTAL_PART_UID,
    IUserData,
    OFFICIAL_NET_PORTAL_PART_UID,
    OFFICIAL_POWER_NET_PORTAL_PART_UID,
    PartLibrary,
    set,
    setElementsAndGenerateNodes,
} from "@buildwithflux/core";
import {
    createNewDocumentData,
    createPartFromDocument,
    createPartVersionFromDocument,
    Enterprise,
    IDocumentData,
    IElementData,
    IElementTerminalBareData,
    IPartData,
    IPartVersionData,
    IPropertiesMap,
    Organization,
    slugifyDocumentName,
} from "@buildwithflux/models";
import {DocumentRepository, PartRepository, PartVersionRepository} from "@buildwithflux/repositories";
import {guid, Logger} from "@buildwithflux/shared";
import {mapValues, uniq} from "lodash";

import {StorageError} from "../../redux/reducers/app/actions";
import {WireProjectionSimple} from "../schematic_editor/wiring/WireProjectionSimple";
import Wiring from "../schematic_editor/wiring/Wiring";

import {AnalyticsStorage} from "./AnalyticsStorage";
import {DeprecatedTrackingEvents} from "./common/DeprecatedTrackingEvents";
import {DocumentStorageHelper} from "./helpers/DocumentStorageHelper";

export class PartStorage {
    constructor(
        private readonly partLibrary: PartLibrary,
        private readonly documentRepository: DocumentRepository,
        private readonly partRepository: PartRepository,
        private readonly partVersionRepository: PartVersionRepository,
        private readonly analyticsStorage: AnalyticsStorage,
        private readonly logger: Logger,
    ) {}

    /**
     * TODO: this should not be in PartStorage because it does not actually store anything
     */
    public static connectNetsInDocument(
        externalTerminals: IElementTerminalBareData[],
        nets: Record<string, IElementTerminalBareData[]>,
        document: IDocumentData,
        userUid: string,
        genesisTerminalPart: IPartVersionData,
    ) {
        const genesisTerminalId = Object.values(genesisTerminalPart?.terminals ?? {})[0]?.uid;
        if (!genesisTerminalPart || !genesisTerminalId) {
            throw new Error("Genesis terminal does not exist or is ill-defined");
        }

        function getTerminalPosition(doc: IDocumentData, terminal: IElementTerminalBareData) {
            const element = doc.elements[terminal.element_uid];
            if (!element) return;
            return ElementHelper.getAbsoluteTerminalPlacements(element)[terminal.terminal_uid]?.position;
        }

        const wiring = new Wiring(document.routeVertices, document.elements);

        const externalTerminalMap = new Map<IElementTerminalBareData, string>();
        for (const terminal of externalTerminals) {
            const position = getTerminalPosition(document, terminal);
            if (!position) continue;
            const newTerminal = ElementHelper.createNewElementData(genesisTerminalPart, position, userUid);
            document.elements[newTerminal.uid] = newTerminal;

            const genesisTerminal = {element_uid: newTerminal.uid, terminal_uid: genesisTerminalId};

            wiring.placeWire(
                {terminal: genesisTerminal, position},
                {terminal, position},
                new WireProjectionSimple(),
                // NOTE: normalize false is important for perf
                {normalize: false},
            );

            externalTerminalMap.set(terminal, newTerminal.uid);
        }

        for (const net of Object.values(nets)) {
            for (let idx = 1; idx < net.length; idx++) {
                const t1 = net[idx - 1];
                const t2 = net[idx];
                if (!t1 || !t2) continue;
                const p1 = getTerminalPosition(document, t1);
                const p2 = getTerminalPosition(document, t2);
                if (!p1 || !p2) continue;
                wiring.placeWire(
                    {terminal: t1, position: p1},
                    {terminal: t2, position: p2},
                    new WireProjectionSimple(),
                    // NOTE: normalize false is important for perf
                    {normalize: false},
                );
            }
        }
        document.routeVertices = wiring.serializeVertices();
        return externalTerminalMap;
    }

    public async setArchived(partUid: string, archived: boolean) {
        await this.updatePart(partUid, {archived});

        if (archived) {
            this.analyticsStorage.logEvent(DeprecatedTrackingEvents.archivePart, {
                content_type: "part",
                content_id: partUid,
            });
        } else {
            this.analyticsStorage.logEvent(DeprecatedTrackingEvents.unarchivePart, {
                content_type: "part",
                content_id: partUid,
            });
        }
    }

    public async deletePartAndPartVersionsByPartUid(partUid: string) {
        if (partUid === GENESIS_TERMINAL_PART_UID) {
            this.logger.error({message: "Thou shall not delete thy genesis Terminal!"});
            throw new Error("Thou shall not delete thy genesis Terminal!");
        }

        this.analyticsStorage.logEvent(DeprecatedTrackingEvents.deletePartAndPartVersions, {
            content_type: "part",
            content_id: partUid,
        });
        const partVersions = await this.partVersionRepository.getAll(partUid);
        await Promise.all(
            partVersions.map((partVersion) => this.partVersionRepository.destroy(partUid, partVersion.version)),
        );
        return this.partRepository.destroy(partUid);
    }

    public getPartByUid(partUid: string) {
        return this.partRepository.get(partUid);
    }

    public listenToPartByUid(
        partUid: string,
        callbackSuccess: (partData: IPartData) => void,
        // QUESTION: Do we need this error callback?  What would it even do?
        _callbackError?: (error: StorageError) => void,
    ) {
        return this.partRepository.subscribe(partUid, callbackSuccess);
    }

    // TODO: remove headVersionName default arg
    public getPartVersionByPartUidAndVersion(partUid: string, partVersion: string = headVersionName) {
        return this.partVersionRepository.get(partUid, partVersion);
    }

    public async getLatestVersion(partUid: string): Promise<IPartVersionData> {
        return this.getLatestNonHeadVersion(partUid).then((result) => {
            return result ?? this.getPartVersionByPartUidAndVersion(partUid, headVersionName);
        });
    }

    /**
     * NOTE: `createLocalPartFromElement` is only intended for developer use / as an example. It does not
     * convert to a complete copy of the given component, and doesn't guarantee a well-formed document.
     */
    public async createLocalPartFromElement(
        hostProject: IDocumentData,
        element: IElementData,
        owner: IUserData,
        ownerOrganization: Organization | undefined,
        ownerEnterprise: Enterprise | undefined,
    ): Promise<{partVersion: IPartVersionData}> {
        const partVersionData = element.part_version_data_cache;

        const newPartDocumentData = createNewDocumentData(owner, ownerOrganization, ownerEnterprise);
        Object.assign(newPartDocumentData, {
            // This is the approx. inverse of `partVersionFromUnpublishedDocument` -- yet another case where
            // it would make more sense for part versions and documents to share a common interface.
            description: partVersionData.description ?? "",
            name: partVersionData.name,
            // TODO: if this is not an unallocated slug, it may conflict and produce a document that can't be reached
            slug: slugifyDocumentName(partVersionData.name),
            elements: mapValues(partVersionData.extracted_element_cache, (subElement, uid) =>
                ElementHelper.createNewElementData(
                    {
                        properties: (subElement.properties ?? {}) as IPropertiesMap,
                        owner_uid: owner.uid,
                        created_at: Date.now(),
                        terminals: {},
                        ...(subElement.part_version_data_cache as Pick<
                            IPartVersionData,
                            "name" | "part_uid" | "version"
                        >),
                    },
                    subElement.diagram_position ?? {x: 0, y: 0},
                    owner.uid,
                    subElement.label,
                    uid,
                ),
            ),
        });
        return this.saveLocalPart(newPartDocumentData, this.createLocalPart(hostProject.uid, newPartDocumentData));
    }

    /**
     * Create a local part (and associated document) scoped to the specified
     * project, using the given document data as the basis for the part's
     * contents.
     *
     * Does NOT add the part to the host document -- the caller should do this
     * in the normal way as for any other part. See CreateLocalPartMenuItem.tsx
     * for an example of how to do this.
     */
    public createLocalPart(hostProjectUid: IDocumentData["uid"], newPartDocumentData: IDocumentData) {
        newPartDocumentData.localToDocumentUid = hostProjectUid;
        newPartDocumentData.slug = `local:${newPartDocumentData.slug}`;

        const newVersion = guid();
        const newPart = createPartFromDocument(newPartDocumentData, newVersion);
        const newPartVersion = createPartVersionFromDocument(newPartDocumentData, newPart.uid, newVersion);

        return {part: newPart, partVersion: newPartVersion, document: newPartDocumentData};
    }

    public async saveLocalPart(
        newPartDocumentData: IDocumentData,
        partAndVersion: {part: IPartData; partVersion: IPartVersionData},
    ) {
        // Save document and publish as part, per process in `createPartFromSubjects`:
        await this.documentRepository.save(newPartDocumentData);

        const {part, partVersion} = partAndVersion;
        await this.savePartAndPartVersion(part, partVersion);

        await this.documentRepository.save(
            {...newPartDocumentData, belongs_to_part_uid: part.uid},
            [],
            newPartDocumentData,
        );
        return {part, partVersion};
    }

    public async createPartFromSubjects(
        hostDocument: IDocumentData,
        elementsToRemoveFromHost: IElementData[],
        ownerUserData: IUserData,
        ownerOrganization: Organization | undefined,
    ) {
        // Create a new part document
        const newPartDocument = createNewDocumentData(ownerUserData, ownerOrganization);

        newPartDocument.configs = hostDocument.configs;
        newPartDocument.properties = hostDocument.properties;
        newPartDocument.pcbLayoutRuleSets = hostDocument.pcbLayoutRuleSets;
        elementsToRemoveFromHost.forEach((element) => {
            newPartDocument.elements[element.uid] = element;
        });

        // Add the selected elements to the new part document
        setElementsAndGenerateNodes(newPartDocument, elementsToRemoveFromHost);

        const internalNets: Record<string, IElementTerminalBareData[]> = {};
        const internalTerminals: Record<string, string> = {};
        for (const net of Object.values(hostDocument.nets)) {
            const isInternal = Object.values(net.pins).every((p) => p.elementUid in newPartDocument.elements);
            if (isInternal) {
                internalNets[net.uid] = Object.values(net.pins).map((p) => ({
                    element_uid: p.elementUid,
                    terminal_uid: p.terminalUid,
                }));
                for (const pin of Object.values(net.pins)) {
                    internalTerminals[`${pin.elementUid}:${pin.terminalUid}`] = net.uid;
                }
            }
        }

        const externalTerminals: IElementTerminalBareData[] = [];
        for (const element of Object.values(newPartDocument.elements)) {
            for (const terminalUid of Object.keys(element.part_version_data_cache.terminals)) {
                const terminal = {element_uid: element.uid, terminal_uid: terminalUid};
                const terminalId = `${element.uid}:${terminalUid}`;
                if (!(terminalId in internalTerminals)) {
                    externalTerminals.push(terminal);
                }
            }
        }

        const wiring = new Wiring(hostDocument.routeVertices, hostDocument.elements);
        const verticesToRemove: string[] = [];
        for (const vertex of wiring.vertices.values()) {
            const terminal = vertex.terminal;
            if (!terminal) continue;
            if (`${terminal.element_uid}:${terminal.terminal_uid}` in internalTerminals) {
                const netVertices = wiring.getAllNetVerticesAcrossPortals(vertex);
                verticesToRemove.push(...netVertices.map((v) => v.id));
            }
        }

        // Return the external-to-internal-facing terminal map, so the caller can reconnect
        // them in the host document.
        const externalTerminalMap = PartStorage.connectNetsInDocument(
            externalTerminals,
            internalNets,
            newPartDocument,
            ownerUserData.uid,
            await this.getGenesisTerminal(),
        );

        // important to setDocument before the partVersion for permissions reasons
        await this.documentRepository.save(newPartDocument);
        const {part, partVersion} = await this.partLibrary.publishToLibrary(newPartDocument);
        const publishedPartDoc = {...newPartDocument, belongs_to_part_uid: part.uid};
        // sigh, we have to do this twice, but we can tell the repository not to traverse subcollections
        // and only process the base document.
        await this.documentRepository.save(publishedPartDoc, [], newPartDocument);

        return {partVersion, externalTerminalMap, verticesToRemove: uniq(verticesToRemove)};
    }

    public async savePartAndPartVersion(part: IPartData, partVersion: IPartVersionData) {
        // NOTE: the order of these saves could be important
        await this.savePart(part);
        return await this.savePartVersion(partVersion);
    }

    // QUESTION: is this used publicly?
    public savePart(part: IPartData) {
        if (part.uid === GENESIS_TERMINAL_PART_UID) {
            this.logger.error({message: "Thou shall not update thy genesis Terminal!"});
            throw new Error("Thou shall not update thy genesis Terminal!");
        }

        this.analyticsStorage.logEvent(DeprecatedTrackingEvents.setPart, {
            content_type: "part",
            content_id: part.uid,
        });

        return this.partRepository.save(part);
    }

    public savePartVersion(partVersion: IPartVersionData) {
        if (partVersion.part_uid === GENESIS_TERMINAL_PART_UID) {
            this.logger.error({message: "Thou shall not overwrite thy genesis Terminal!"});
            throw new Error("Thou shall not overwrite thy genesis Terminal!");
        }

        this.analyticsStorage.logEvent(DeprecatedTrackingEvents.setPartVersion, {
            content_type: "part_version",
            content_id: partVersion.part_uid,
        });

        return this.partVersionRepository.save(partVersion).then(() => partVersion);
    }

    public getGenesisTerminal(): Promise<IPartVersionData> {
        return this.getSystemPartVersion(GENESIS_TERMINAL_PART_UID);
    }

    public getGroundTerminal(): Promise<IPartVersionData> {
        return this.getSystemPartVersion(DEFAULT_GROUND_TERMINAL_PART_UID);
    }

    public getGroundPortal(): Promise<IPartVersionData> {
        return this.getSystemPartVersion(GROUND_PORTAL_PART_UID);
    }

    public getPowerNetPortal(): Promise<IPartVersionData> {
        return this.getSystemPartVersion(OFFICIAL_POWER_NET_PORTAL_PART_UID);
    }

    public getNetPortal(): Promise<IPartVersionData> {
        return this.getSystemPartVersion(OFFICIAL_NET_PORTAL_PART_UID);
    }

    public getResistor(): Promise<IPartVersionData> {
        return this.getSystemPartVersion("6119de18-9a9b-417f-87c4-eb0b6fedb106"); // TODO: fix, these are placeholder parts, cloned from originals, created under dacre's account
    }

    public getCapacitor(): Promise<IPartVersionData> {
        return this.getSystemPartVersion("bb27908c-281b-4545-b7bd-1e065b23e9dc"); // TODO: fix, these are placeholder parts, cloned from originals, created under dacre's account
    }

    public getInductor(): Promise<IPartVersionData> {
        return this.getSystemPartVersion("7b5dd350-1c0d-48bf-93a3-0edcbd5162d6"); // TODO: fix, these are placeholder parts, cloned from originals, created under rex-flux's account
    }

    public getLED(): Promise<IPartVersionData> {
        return this.getSystemPartVersion("748bd064-e4ba-4d53-b5eb-102c9d0a45b2"); // TODO: fix, these are placeholder parts, cloned from originals, created under dacre's account
    }

    private async getLatestNonHeadVersion(partUid: string): Promise<IPartVersionData | undefined> {
        return this.partRepository
            .get(partUid)
            .then((part) => {
                return part?.latest_version;
            })
            .then((latestVersionName) => {
                return this.getPartVersionByPartUidAndVersion(partUid, latestVersionName);
            });
    }

    private async getSystemPartVersion(partUid: string): Promise<IPartVersionData> {
        const cached = get(partUid);

        if (cached) {
            return cached as IPartVersionData;
        }

        const partVersion = await this.getPartVersionByPartUidAndVersion(partUid);

        set(partUid, partVersion);

        return partVersion;
    }

    private updatePart(partUid: string, attributesToUpdate: Partial<IPartData>) {
        if (partUid === GENESIS_TERMINAL_PART_UID) {
            this.logger.error("Thou shall not update thy genesis Terminal!");
            throw new Error("Thou shall not update thy genesis Terminal!");
        }

        attributesToUpdate.updated_at = new Date().getTime();

        this.analyticsStorage.logEvent(DeprecatedTrackingEvents.updatePart, {
            content_type: "part",
            content_id: partUid,
        });

        return this.partRepository.update(partUid, attributesToUpdate);
    }

    /**
     * If the subcircuit we are converting contains only one element, this will ensure
     * that the new part will have the same graphical symbol as that element.
     * @param newPartVersion
     * @param documentData
     */
    private inheritSymbolResourceFile(newPartVersion: IPartVersionData, documentData: IDocumentData) {
        const nonTerminalElements = DocumentStorageHelper.getNonTerminalElements(documentData.elements);
        if (nonTerminalElements.length > 0) {
            const referencePartVersion = nonTerminalElements[0]!.part_version_data_cache;
            if (nonTerminalElements.length === 1) {
                newPartVersion.symbol_resource_file = referencePartVersion.symbol_resource_file || "";
                newPartVersion.preview_image = referencePartVersion.preview_image || "";
                newPartVersion.properties = referencePartVersion.properties;

                if (newPartVersion.terminals.size! > referencePartVersion.terminals.size!) {
                    // TODO@Natarius: this might fuck up the order
                    Object.values(referencePartVersion.terminals).forEach((terminal, index) => {
                        Object.values(newPartVersion.terminals)[index]!.position = terminal.position;
                    });
                } else {
                    newPartVersion.terminals = referencePartVersion.terminals;
                }
            }
        }

        return newPartVersion;
    }
}
