import {IChatMessage, IChatMessageHumanMetaData, ICopilotActions} from "@buildwithflux/models";
import {ChatRepository} from "@buildwithflux/repositories";
import {Logger} from "@buildwithflux/shared";
import {mapValues, omit, values} from "lodash";
import {uuid} from "short-uuid";

import {CurrentUserService} from "../../../../../../../../../../modules/auth";
import {createSortedThreadMessagesSelector} from "../selectors/createSortedThreadMessagesSelector";
import {CopilotChatStoreState} from "../useCopilotChatStore";

export function createMessage(
    set: (fn: (state: CopilotChatStoreState) => void) => void,
    get: () => CopilotChatStoreState,
    currentUserService: CurrentUserService,
    chatRepository: ChatRepository,
    logger: Logger,
) {
    return async (threadUid: string, content: string, metaData: IChatMessageHumanMetaData) => {
        const {currentDocumentUid, threads} = get();
        if (!currentDocumentUid) return;

        const currentUser = currentUserService.getCurrentUser();
        if (!currentUser) return;

        const thread = threads[threadUid];
        if (!thread) return;

        const messageUid = uuid();
        const timestamp = Date.now();

        // Get the last message in the thread for the reply chain
        const currentBranchMessages = createSortedThreadMessagesSelector(threadUid)(get());
        const replyingToMessageUid = currentBranchMessages[currentBranchMessages.length - 1]?.uid || "root";

        const newMessage = {
            uid: messageUid,
            belongs_to_thread_uid: threadUid,
            reply_to_message_uid: replyingToMessageUid,
            owner_uid: currentUser.uid,
            owner_handle: currentUser.handle,
            content: content,
            reactions: {},
            meta_data: metaData,
            created_at: timestamp,
            updated_at: timestamp,
        };

        // Optimistically update the state
        set((draft) => {
            const draftThread = draft.threads[threadUid];
            if (draftThread) {
                draftThread.updated_at = timestamp;
                draftThread.messages[messageUid] = newMessage;
            }
        });

        try {
            // Persist the new message to the backend
            await chatRepository.createMessage(currentDocumentUid, threadUid, newMessage);
        } catch (error) {
            logger.error("Failed to create message:", error);

            // Rollback optimistic update on failure
            set((draft) => {
                const draftThread = draft.threads[threadUid];
                if (draftThread) {
                    delete draftThread.messages[messageUid]; // Remove the message
                    draftThread.updated_at = thread.updated_at; // Optionally reset the timestamp
                }
            });
        }
    };
}

export function branchMessage(
    set: (fn: (state: CopilotChatStoreState) => void) => void,
    get: () => CopilotChatStoreState,
    currentUserService: CurrentUserService,
    chatRepository: ChatRepository,
    logger: Logger,
) {
    return async (
        threadUid: string,
        messageUid: string,
        content?: string,
        metaData?: Partial<IChatMessageHumanMetaData>,
    ) => {
        const {currentDocumentUid, threads} = get();
        if (!currentDocumentUid) return;

        const currentUser = currentUserService.getCurrentUser();
        if (!currentUser) return;

        const thread = threads[threadUid];
        if (!thread) return;

        const message = thread.messages[messageUid];

        if (!message || message.meta_data.type !== "human") {
            return;
        }

        const newMessageBranchUid = uuid();
        const timestamp = Date.now();

        const branchedNewMessage: IChatMessage = {
            ...message,
            uid: newMessageBranchUid,
            owner_uid: currentUser.uid,
            owner_handle: currentUser.handle,
            content: content || message.content,
            reactions: {},
            meta_data: {
                ...(message.meta_data as IChatMessageHumanMetaData),
                ...metaData,
            },
            updated_at: timestamp,
        };

        set((draft) => {
            const draftThread = draft.threads[threadUid];

            if (draftThread) {
                draftThread.updated_at = timestamp;
                draftThread.messages[newMessageBranchUid] = branchedNewMessage;
            }
        });

        try {
            // Persist the new branched message to the backend
            await chatRepository.createMessage(currentDocumentUid, threadUid, branchedNewMessage);
        } catch (error) {
            logger.error("Failed to create branched new message:", error);

            // Rollback optimistic update on failure
            set((draft) => {
                const draftThread = draft.threads[threadUid];
                if (draftThread) {
                    delete draftThread.messages[newMessageBranchUid]; // Remove the message
                    draftThread.updated_at = thread.updated_at; // Optionally reset the timestamp
                }
            });
        }
    };
}

export function deleteMessage(
    set: (fn: (state: CopilotChatStoreState) => void) => void,
    get: () => CopilotChatStoreState,
    chatRepository: ChatRepository,
    logger: Logger,
) {
    return async (threadUid: string, messageUid: string) => {
        const {currentDocumentUid, threads} = get();
        if (!currentDocumentUid) return;

        const threadMessages = threads[threadUid]?.messages;
        if (!threadMessages) return;

        const messageToDelete = threadMessages[messageUid];
        if (!messageToDelete) return;

        const messagesToDelete = values(threadMessages).filter(
            (msg) => msg.created_at > messageToDelete.created_at || msg.uid === messageToDelete.uid,
        );

        const messagesToDeleteUids = messagesToDelete.map((msg) => msg.uid);

        set((draft) => {
            const draftThread = draft.threads[threadUid];

            if (draftThread) {
                draftThread.updated_at = new Date().getTime();
                draftThread.messages = omit(threadMessages, messagesToDeleteUids);
            }
        });

        try {
            // delete the message on the backend

            await Promise.all(
                messagesToDeleteUids.map((messageToDeleteUid) =>
                    chatRepository.deleteMessage(currentDocumentUid, threadUid, messageToDeleteUid),
                ),
            );
        } catch (error) {
            logger.error("Failed to delete message(s):", error);

            // Rollback optimistic update on failure
            set((draft) => {
                const draftThread = draft.threads[threadUid];

                if (draftThread) {
                    draftThread.messages = {
                        ...draftThread.messages,
                        ...messagesToDelete.reduce((acc, msg) => ({...acc, [msg.uid]: msg}), {}),
                    };
                }
            });
        }
    };
}

export function setActionExecuted(
    set: (fn: (state: CopilotChatStoreState) => void) => void,
    get: () => CopilotChatStoreState,
    chatRepository: ChatRepository,
    logger: Logger,
) {
    return async (threadUid: string, messageUid: string) => {
        const {currentDocumentUid, threads} = get();
        if (!currentDocumentUid) return;

        const threadMessages = threads[threadUid]?.messages;
        if (!threadMessages) return;

        const message = threadMessages[messageUid];
        if (!message) return;

        if (message.meta_data.type !== "copilot") return;

        set((draft) => {
            const draftThread = draft.threads[threadUid];
            const draftMessage = draftThread?.messages[messageUid];

            if (draftMessage?.meta_data.type === "copilot") {
                mapValues(draftMessage.meta_data.actions, (action) => {
                    if (action) {
                        action.applied = true;
                    }
                });
            }
        });

        try {
            if (message.meta_data?.actions) {
                await chatRepository.setMessage(currentDocumentUid, threadUid, {
                    ...message,
                    meta_data: {
                        ...message.meta_data,
                        actions: mapValues(message.meta_data.actions, (action) => {
                            if (action) {
                                return {
                                    ...action,
                                    applied: true,
                                };
                            }
                        }) as ICopilotActions,
                    },
                });
            }
        } catch (error) {
            logger.error("Failed to update message:", error);
        }
    };
}
