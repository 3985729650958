import {
    ClipperShapeFactory,
    PcbLayoutRuleCompiler,
    PcbLayoutRuntimeRulesManager,
    PcbNodeBaker,
    PcbNodeHelper,
    RuleParser,
    PcbTreeManager,
    calcKeepoutForNodes,
    createLayoutShape,
    getChildrenOfType,
    getConnectedCopperLayers,
    getDescendantsOfType,
    getRouteSegmentAbsStartEndPos,
    getStackupLayersAndViaConfigs,
    getTopLevelLayoutNonElementDescendantsPads,
    getViaSegmentCanonicalType,
    getViaSegmentFromType,
    vec2,
    PrimitiveRole,
    PcbQuadTreeNode,
    Primitive,
    PcbPrimitiveStore,
    approxFittingPolygon,
} from "@buildwithflux/core";
import {
    AnyPcbBakedNode,
    AutoLayoutApi,
    DeepPcbBoard,
    DeepPcbBoundary,
    DeepPcbClearanceRule,
    DeepPcbComponent,
    DeepPcbComponentDefinition,
    DeepPcbComponentPinId,
    DeepPcbDesignRules,
    DeepPcbDifferentialPair,
    DeepPcbKeepoutType,
    DeepPcbLayer,
    DeepPcbLayerType,
    DeepPcbNet,
    DeepPcbNetClass,
    DeepPcbPadstack,
    DeepPcbPin,
    DeepPcbPlane,
    DeepPcbResolution,
    DeepPcbShape,
    DeepPcbShapeCircle,
    DeepPcbShapePolygon,
    DeepPcbShapeType,
    DeepPcbVia,
    DeepPcbWire,
    FootPrintPadHoleType,
    IDocumentData,
    IPcbBoardLayerBase,
    IPcbBoardLayerCopper,
    IUserData,
    LayerOrientation,
    PcbBakedNode,
    PcbNodeTypes,
    PcbNodesMap,
    PcbRuleValue,
    PcbViaType,
    SmartViaNodeBakedRulesKeys,
    createNetNode,
    createNode,
    createRouteSegmentNode,
    defaultThroughHoleViaUid,
    getLayerIdentifier,
    getOrderedCopperLayers,
} from "@buildwithflux/models";
import {guid, milsToMeters} from "@buildwithflux/shared";
import {DocumentService} from "@buildwithflux/solder-core";
import fileDownload from "js-file-download";
import {Vector3} from "three";

// eslint-disable-next-line boundaries/element-types
import {getActivePcbLayoutNodes} from "../../../../components/navigation_bar/actions_menu/menu_items/export/helpers";
// eslint-disable-next-line boundaries/element-types
import createPseudoNode from "../../../../components/pages/document/components/editor/components/pcb_editor/components/pcb_editor_canvas/nodes/net/helpers/createPseudoNode";
import {ReduxStoreService} from "../../../../export";
import {FluxLogger} from "../../../storage_engine/connectors/LogConnector";
import {usePcbEditorUiStore} from "../../../stores/pcb/PcbEditorUiStore";
import {usePcbLayerViewStore} from "../../../stores/pcb/PcbLayerViewStore";
import {getAllBakedNodes} from "../../../stores/pcb/utils";
import {BaseExporter} from "../BaseExporter";

import DeepPcbHelpers from "./helpers";

export class DeepPcbExporter extends BaseExporter {
    private static readonly DEFAULT_NET_CLASS_ID = "__default__";
    private static readonly UNCONNECTED_NET_ID = "UNCONNECTED_NET";
    private static readonly DEFAULT_NET_CLEARANCE_VALUE = DeepPcbHelpers.toNm(milsToMeters(5)); // 5mils

    private __debug = false;

    private activePcbLayoutNodes: PcbNodesMap<AnyPcbBakedNode>;
    private layoutNode: PcbBakedNode<PcbNodeTypes.layout>;
    private copperLayers: IPcbBoardLayerCopper[];
    private nodeShapesMap: Map<string, PcbQuadTreeNode>;
    private holeTypePadNodes: PcbBakedNode<PcbNodeTypes.pad>[] = [];
    private ruleCompiler: PcbLayoutRuleCompiler;

    private createdElementsCache = new Set<string>();
    private createdPinsCache = new Set<string>();
    private padstacks: Record<string, DeepPcbPadstack> = {};
    private viaDefinitions: string[] = [];

    constructor(
        private readonly autoLayoutVersion: keyof typeof AutoLayoutApi.version,
        documentData: IDocumentData,
        documentOwner: IUserData,
        private readonly pcbPrimitiveStore: PcbPrimitiveStore,
        private readonly reduxStoreService: ReduxStoreService,
        private readonly documentService: DocumentService,
        private readonly clipperShapeFactory: ClipperShapeFactory,
    ) {
        super(documentData, documentOwner);

        const activePcbLayoutNodes = getActivePcbLayoutNodes(documentService);
        if (!activePcbLayoutNodes) {
            throw new Error("No active layout nodes found");
        }
        this.activePcbLayoutNodes = activePcbLayoutNodes;

        const activeLayoutUid = usePcbLayerViewStore.getState().activeLayoutUid;
        if (!activeLayoutUid) {
            throw new Error("No active layout UID found");
        }

        this.layoutNode = this.activePcbLayoutNodes[activeLayoutUid] as PcbBakedNode<PcbNodeTypes.layout>;
        if (!this.layoutNode) {
            throw new Error("No active layout node found");
        }

        this.copperLayers = getOrderedCopperLayers(this.layoutNode.bakedRules.stackup);

        this.nodeShapesMap = this.pcbPrimitiveStore.getAllNodeShapesMap();

        const allBakedNodes = getAllBakedNodes(this.documentService);
        const treeManager = new PcbTreeManager(allBakedNodes, console, true);
        const pcbNodeBaker = new PcbNodeBaker(
            this.documentData,
            this.documentData.pcbLayoutRuleSets,
            new PcbLayoutRuntimeRulesManager(),
            treeManager,
            FluxLogger,
        );
        this.ruleCompiler = pcbNodeBaker.pcbRuleCompiler;
    }

    /**
     * Download the PCB in DeepPCB format with "json" or "deeppcb" extension
     * @param extension - The extension of the file to download
     */
    public download(extension: "json" | "deeppcb") {
        const deepPcbBoard = this.generateBoard();
        const boardString = JSON.stringify(deepPcbBoard, null, 2);

        if (this.__debug) {
            // eslint-disable-next-line no-console
            console.log(boardString);
        } else {
            fileDownload(boardString, this.createFileName(extension));
        }
    }

    /**
     * Generates and returns a JSON of the PCB in DeepPCB format
     */
    public generateBoard(): DeepPcbBoard {
        // Generate the board boundary and planes
        const boundary = this.generateBoundary();
        const {planes, fillNetNodes} = this.generatePlanes(boundary);

        // Generate the components and their definitions
        // This will also populate the padstacks with component pads' padstacks
        const {componentDefinitions, components} = this.generateComponents();

        // Create padstacks for all throughHole via configs defined in the layout stackup
        // This will populate the viaDefinitions and padstacks class properties
        // viaDefinition == padstackId == viaConfigUid
        const throughHoleViaConfigUids = this.generatePadstacksForAllViaConfigs();

        // Generate the existing vias, and via definitions from the stackup
        // This will also populate the padstacks with via definition padstacks
        const vias = this.generateVias();

        // Generate the layers as well as keepouts for zones and board holes
        // Make sure to generate keepouts _after_ generating components and vias,
        // since the above methods will populate the `holeTypePadNodes` array which is used
        // to create keepouts for hole pads
        const layers = this.generateLayers();
        this.generateKeepouts(layers, fillNetNodes);

        // Generate the nets and netclasses
        const nets = this.generateNets();
        const {netClasses, netUidToNetClassMap} = this.generateNetClasses(nets, throughHoleViaConfigUids);

        // Generate differential pairs
        const netUidToNetMap = Object.fromEntries(nets.map((net) => [net.id, net]));
        const differentialPairs = this.generateDifferentialPairs(netUidToNetMap, netUidToNetClassMap);

        // Generate the rules
        // We need nets and fillNetNodes to generate clearance rules
        const rules = this.generateRules(nets, fillNetNodes);

        // Once all padstacks are generated by the methods above, create the list of padstacks
        const padstacks = Object.values(this.padstacks);

        return {
            name: this.generateName(),
            rules,
            resolution: this.generateResolution(),
            boundary,
            componentDefinitions,
            components,
            layers,
            netClasses,
            nets,
            differentialPairs,
            padstacks,
            planes,
            viaDefinitions: this.viaDefinitions,
            vias,
            wires: this.generateWires(),
        };
    }

    /** Generates a file name for the DeepPCB file */
    private generateName(): string {
        return this.documentData.name;
    }

    /** Generate the rules for the DeepPCB board */
    private generateRules(nets: DeepPcbNet[], fillNetNodes: PcbBakedNode<PcbNodeTypes.net>[]): DeepPcbDesignRules {
        const rules: DeepPcbDesignRules = [
            {type: "allowViaAtSmd", value: true},
            {type: "rotateFirst", value: false},
        ];

        if (this.autoLayoutVersion === "v1") {
            rules.push({type: "pinConnectionPoint", value: "position"});
        }

        // Create a clearance value for every fill net node with every other net node
        for (const fillNetNode of fillNetNodes) {
            for (const net of nets) {
                if (net.id === fillNetNode.uid) continue;
                const netNode = this.activePcbLayoutNodes[net.id] as PcbBakedNode<PcbNodeTypes.net>;
                if (!netNode) continue;

                // The keepout value between the fill net node and every other net node
                const keepout = calcKeepoutForNodes(fillNetNode, netNode, this.ruleCompiler.pcbLayoutSelectorMatching);
                const clearanceValue = Math.max(keepout, DeepPcbExporter.DEFAULT_NET_CLEARANCE_VALUE);

                const clearanceRule: DeepPcbClearanceRule = {
                    subjects: [
                        {id: fillNetNode.uid, type: "netClass"},
                        {id: net.id, type: "netClass"},
                    ],
                    type: "clearance",
                    value: clearanceValue,
                };

                rules.push(clearanceRule);
            }
        }

        return rules;
    }

    /**
     * Generate the resolution for the DeepPCB board
     * Current resolution: 1nm (1{unit}/{value})
     * This resolution should be smaller than our connectivityTolerance (200nm)
     * to prevent airwires from appearing.
     * */
    private generateResolution(): DeepPcbResolution {
        return {
            unit: "um",
            value: 1000,
        };
    }

    private generateBoundary(): DeepPcbBoundary {
        const shape = createLayoutShape(this.layoutNode.bakedRules);
        if (!shape) {
            throw new Error("Could not create shape for layout node");
        }

        const points = shape.getPoints(64).map((point) => DeepPcbHelpers.getPoint(point));

        return {
            // This will be updated by `generatePlanes` to use the max clearance from the layout edge
            clearance: 0,
            shape: {
                type: DeepPcbShapeType.polyline,
                points,
            },
        };
    }

    private getLayerOrder(layer: string | IPcbBoardLayerCopper | IPcbBoardLayerBase) {
        const layerId = typeof layer === "string" ? layer : getLayerIdentifier(layer);
        return this.copperLayers.findIndex((copperLayer) => getLayerIdentifier(copperLayer) === layerId);
    }

    private generateKeepouts(layers: DeepPcbLayer[], fillNetNodes: PcbBakedNode<PcbNodeTypes.net>[]) {
        // DeepPCB doesn't support "Everything" type right now
        const keepoutType: DeepPcbKeepoutType[] = ["Wire", "Via"];

        // Create a cache of copperLayer->connectedFillNetNodes to avoid recalculating every time
        const copperLayersToConnectedFillNetNodesMap = fillNetNodes.reduce((acc, fillNetNode) => {
            const netConnectedLayers = getConnectedCopperLayers(
                fillNetNode,
                this.activePcbLayoutNodes,
                this.copperLayers,
            );
            for (const connectedLayer of netConnectedLayers) {
                const layerOrder = this.getLayerOrder(connectedLayer);
                const connectedFillNetNodes = acc[layerOrder] ?? [];
                connectedFillNetNodes.push(fillNetNode);
                acc[layerOrder] = connectedFillNetNodes;
            }
            return acc;
        }, {} as Record<string, PcbBakedNode<PcbNodeTypes.net>[]>);

        // 1. Generate keepouts for zone nodes
        const zoneNodes = getDescendantsOfType(this.activePcbLayoutNodes, this.layoutNode, PcbNodeTypes.zone);
        for (const zoneNode of zoneNodes) {
            const zoneShapeData = this.nodeShapesMap.get(zoneNode.uid);
            if (!zoneShapeData) continue;

            // Zones always only have one shape
            const [zoneShape] = zoneShapeData.primitives;
            if (!zoneShape) continue;

            // We want to use polygon instead of DeepPCB's "circle" or "rectangle"
            // primitives to ensure that scale, rotation, or any other mutation is respected
            // Using the PCBShapeStore provides this.
            const zoneConnectedLayers = zoneNode.bakedRules.connectedLayers ?? ["All"];
            const zoneLayerOrders = this.copperLayers.reduce((acc, layer, index) => {
                const layerId = getLayerIdentifier(layer);
                if (zoneConnectedLayers.includes(layerId) || zoneConnectedLayers.includes("All")) {
                    acc.push(index);
                }
                return acc;
            }, [] as number[]);
            if (zoneLayerOrders.length === 0) continue;

            for (const layerOrder of zoneLayerOrders) {
                // Compute the keepout by which we should expand the zone shape
                const connectedFillNetNodes = copperLayersToConnectedFillNetNodesMap[layerOrder] ?? [];
                const maxKeepoutFromAnyFillNet = connectedFillNetNodes.reduce((acc, fillNetNode) => {
                    const keepout = calcKeepoutForNodes(
                        zoneNode,
                        fillNetNode,
                        this.ruleCompiler.pcbLayoutSelectorMatching,
                    );
                    return Math.max(acc, keepout);
                }, 0);
                const expandedZonePolygon = DeepPcbHelpers.expandShape(
                    this.clipperShapeFactory,
                    zoneShape,
                    maxKeepoutFromAnyFillNet,
                );

                layers[layerOrder]!.keepouts.push({
                    type: keepoutType,
                    layer: layerOrder,
                    shape: {
                        type: DeepPcbShapeType.polygon,
                        points: expandedZonePolygon.map((point) => DeepPcbHelpers.getPoint(point)),
                    },
                });
            }
        }

        // 2. Generate keepouts for board holes
        // Hole goes through all layers
        for (const holePadNode of this.holeTypePadNodes) {
            const nodeShapes = this.nodeShapesMap.get(holePadNode.uid);
            if (!nodeShapes) continue;

            const holeShapes = nodeShapes.primitives.filter((shape) => shape.role === PrimitiveRole.layoutHole);
            if (holeShapes.length === 0) continue;

            // Hole primitives go through all the layers
            for (const copperLayer of this.copperLayers) {
                // Compute the keepout by which we should expand the hole shape
                const layerOrder = this.getLayerOrder(copperLayer);
                const connectedFillNetNodes = copperLayersToConnectedFillNetNodesMap[layerOrder] ?? [];
                const maxKeepoutFromAnyFillNet = connectedFillNetNodes.reduce((acc, fillNetNode) => {
                    const keepout = calcKeepoutForNodes(
                        holePadNode,
                        fillNetNode,
                        this.ruleCompiler.pcbLayoutSelectorMatching,
                    );
                    return Math.max(acc, keepout);
                }, 0);
                const expandedHoleShapes = holeShapes.map((holeShape): DeepPcbShapePolygon => {
                    const expandedHolePolygon = DeepPcbHelpers.expandShape(
                        this.clipperShapeFactory,
                        holeShape,
                        maxKeepoutFromAnyFillNet,
                    );
                    return {
                        type: DeepPcbShapeType.polygon,
                        points: expandedHolePolygon.map((point) => DeepPcbHelpers.getPoint(point)),
                    };
                });

                expandedHoleShapes.forEach((expandedHoleShape) => {
                    layers[layerOrder]!.keepouts.push({
                        type: keepoutType,
                        layer: layerOrder,
                        shape: expandedHoleShape,
                    });
                });
            }
        }
    }

    private generateLayers(): DeepPcbLayer[] {
        const deepPcbLayers: DeepPcbLayer[] = this.copperLayers.map((layer, order) => {
            const layerId =
                layer.orientation === LayerOrientation.top
                    ? "F.Cu"
                    : layer.orientation === LayerOrientation.bottom
                    ? "B.Cu"
                    : `In${order}.Cu`;
            const type: DeepPcbLayerType = layer.type === "Power Plane" ? "power" : "signal";
            return {
                id: layerId,
                type,
                keepouts: [],
            };
        });

        return deepPcbLayers;
    }

    private generateNets(): DeepPcbNet[] {
        const nets: DeepPcbNet[] = [];

        const reduxState = this.reduxStoreService.getStore().getState();
        const documentNets = reduxState.document?.nets ?? {};
        for (const net of Object.values(documentNets)) {
            const pins: DeepPcbComponentPinId[] = [];
            for (const pin of Object.values(net.pins ?? {})) {
                const elementNode = this.activePcbLayoutNodes[pin.elementUid] as PcbBakedNode<PcbNodeTypes.element>;
                const pinNode = this.activePcbLayoutNodes[pin.uid] as PcbBakedNode<PcbNodeTypes.pad>;
                if (!elementNode || !pinNode) continue;

                const elementName = DeepPcbHelpers.getUniqueNodeName(elementNode);
                const pinName = DeepPcbHelpers.getUniqueNodeName(pinNode);

                // Prevent adding connections to a net for elements or pins that are not created
                if (!this.createdElementsCache.has(elementName)) continue;
                if (!this.createdPinsCache.has(pinName)) continue;

                pins.push(`${elementName}-${pinName}` as DeepPcbComponentPinId);
            }

            // Push this net even if `pins` is empty, since the net might be used by vias/traces
            nets.push({
                id: net.uid,
                pins,
            });
        }

        // Add the additionalNets to the nets list. These are pinless nets that are needed to support
        // vias that are not connected to any net
        nets.push({
            id: DeepPcbExporter.UNCONNECTED_NET_ID,
            pins: [],
        });

        return nets;
    }

    private createPseudoBakedSmartViaNode(netNode: PcbBakedNode<PcbNodeTypes.net>, viaConfigUids?: string) {
        const rules: {[key in SmartViaNodeBakedRulesKeys]?: PcbRuleValue} = {connectedLayers: "All"};
        if (viaConfigUids) {
            rules.viaOptions = viaConfigUids;
        }
        const newSmartViaNode = createNode(
            PcbNodeTypes.smartVia,
            "DummySmartVia",
            {
                uid: guid(),
                parentUid: netNode.uid,
            },
            rules,
        );
        return createPseudoNode(newSmartViaNode, this.documentData, this.documentService);
    }

    private createPseudoBakedTraceNode(netNode: PcbBakedNode<PcbNodeTypes.net>, layer = LayerOrientation.top) {
        const newTraceNode = createRouteSegmentNode(guid(), guid(), new Vector3(), new Vector3(), netNode.uid, layer);
        return createPseudoNode(newTraceNode, this.documentData, this.documentService, [netNode]);
    }

    private generateDefaultNetClass(throughHoleViaConfigUids: string[]): DeepPcbNetClass {
        // Create a baked net node to extract trackwidth and clearance values
        const newNetNode = createNetNode(guid(), this.layoutNode.uid, "DummyNet", {topLevel: [], submodule: []});
        const pseudoBakedNetNode = createPseudoNode(newNetNode, this.documentData, this.documentService);
        if (!pseudoBakedNetNode) throw new Error("Could not create pseudo node for net");

        const pseudoBakedTraceNode = this.createPseudoBakedTraceNode(pseudoBakedNetNode);
        if (!pseudoBakedTraceNode) throw new Error("Could not create pseudo node for trace");
        const trackWidth = DeepPcbHelpers.toNm(pseudoBakedTraceNode.bakedRules.size.x);

        // Create a baked smart via node to extract the default via definition
        const pseudoBakedSmartViaNode = this.createPseudoBakedSmartViaNode(
            pseudoBakedNetNode,
            throughHoleViaConfigUids.join(","),
        );
        if (!pseudoBakedSmartViaNode) throw new Error("Could not create pseudo node for smart via");
        const viaDefinition = pseudoBakedSmartViaNode.bakedRules.selectedViaConfigs[0]?.uid ?? defaultThroughHoleViaUid;
        // This should never happen, but as a safety check
        if (!this.viaDefinitions.includes(viaDefinition)) {
            throw new Error("Default via definition not found in via definitions");
        }

        return {
            id: DeepPcbExporter.DEFAULT_NET_CLASS_ID,
            nets: [],
            trackWidth,
            clearance: DeepPcbExporter.DEFAULT_NET_CLEARANCE_VALUE,
            viaDefinition,
        };
    }

    private generateNetClasses(deepPcbNets: DeepPcbNet[], throughHoleViaConfigUids: string[]) {
        const defaultNetClass = this.generateDefaultNetClass(throughHoleViaConfigUids);
        const netClasses: Record<string, DeepPcbNetClass> = {
            [defaultNetClass.id]: defaultNetClass,
        };

        // Create a map of netId to netClass for easy lookup
        const deepPcbNetsMap = Object.fromEntries(deepPcbNets.map((net) => [net.id, net]));

        const netUidToNetClassMap: Record<string, DeepPcbNetClass> = {};
        const netNodes = getDescendantsOfType(this.activePcbLayoutNodes, this.layoutNode, PcbNodeTypes.net);
        for (const netNode of netNodes) {
            // Filter out this net if it isn't present in deepPcbNets
            if (!deepPcbNetsMap[netNode.uid]) continue;

            const pseudoBakedTraceNode = this.createPseudoBakedTraceNode(netNode);
            if (!pseudoBakedTraceNode) continue;
            const traceWidth = DeepPcbHelpers.toNm(pseudoBakedTraceNode.bakedRules.size.x);

            // Add a prospective smart via node to check what the via options turn out to be
            let pseudoBakedSmartViaNode = this.createPseudoBakedSmartViaNode(netNode);
            if (!pseudoBakedSmartViaNode) continue;
            // Remove any non-throughHole via options to get the throughHole via options
            const viaConfigUids = pseudoBakedSmartViaNode.bakedRules.viaOptions;
            const throughHoleViaOptions = viaConfigUids.filter((uid) => throughHoleViaConfigUids.includes(uid));
            // Re-create the pseudo node with only the throughHole via options
            pseudoBakedSmartViaNode = this.createPseudoBakedSmartViaNode(netNode, throughHoleViaOptions.join(","));
            if (!pseudoBakedSmartViaNode) continue;
            const viaDefinition =
                pseudoBakedSmartViaNode.bakedRules.selectedViaConfigs[0]?.uid ?? defaultThroughHoleViaUid;

            // Otherwise, create a new net class
            const netClassId = netNode.uid;
            const netClass: DeepPcbNetClass = netClasses[netClassId] ?? {
                id: netClassId,
                nets: [],
                trackWidth: traceWidth,
                clearance: DeepPcbExporter.DEFAULT_NET_CLEARANCE_VALUE,
                viaDefinition,
            };
            netClass.nets.push(netNode.uid);
            netClasses[netClassId] = netClass;
            netUidToNetClassMap[netNode.uid] = netClass;
        }

        // Add `UNCONNECTED_NET_ID` to the default net class
        defaultNetClass.nets.push(DeepPcbExporter.UNCONNECTED_NET_ID);

        return {netClasses: Object.values(netClasses), netUidToNetClassMap};
    }

    private generateDifferentialPairs(
        netUidToNetMap: Record<string, DeepPcbNet>,
        netUidToNetClassMap: Record<string, DeepPcbNetClass>,
    ): DeepPcbDifferentialPair[] {
        const differentialPairs: DeepPcbDifferentialPair[] = [];

        const {multiRoutingCache} = usePcbEditorUiStore.getState();
        if (multiRoutingCache) {
            for (const multiRoutingGroup of multiRoutingCache.multiRoutingGroups) {
                // We only support pair-routing right now, so we assuming that there are only 2 nets in a group
                const [netUid1, netUid2] = multiRoutingGroup.netUids;
                if (!netUid1 || !netUid2) continue;

                const netClass1 = netUidToNetClassMap[netUid1];
                const netClass2 = netUidToNetClassMap[netUid2];
                if (!netClass1 || !netClass2) continue;

                const net1 = netUidToNetMap[netUid1];
                const net2 = netUidToNetMap[netUid2];
                if (!net1 || !net2) continue;

                // Skip this group if the nets are not of the same length, otherwise DeepPCB complains
                if (net1.pins.length !== net2.pins.length) continue;

                const clearance = Math.min(netClass1.clearance, netClass2.clearance);
                const trackWidth = Math.min(netClass1.trackWidth, netClass2.trackWidth);

                if (netUid1 && netUid2) {
                    differentialPairs.push({
                        netId1: netUid1,
                        netId2: netUid2,
                        gap: clearance,
                        trackWidth,
                    });
                }
            }
        }

        return differentialPairs;
    }

    private generatePlanes(boardBoundary: DeepPcbBoundary) {
        const data: {
            planes: DeepPcbPlane[];
            fillNetNodes: PcbBakedNode<PcbNodeTypes.net>[];
        } = {planes: [], fillNetNodes: []};

        // Use the fill primitives map to get the nets with fills
        const fillShapesMap = this.pcbPrimitiveStore.getFillShapesMap();
        if (!fillShapesMap) {
            return data;
        }

        data.fillNetNodes = Object.keys(fillShapesMap)
            .map((uid) => this.activePcbLayoutNodes[uid])
            .filter((node): node is PcbBakedNode<PcbNodeTypes.net> => node?.type === PcbNodeTypes.net);
        if (data.fillNetNodes.length === 0) {
            return data;
        }

        let maxClearanceFromLayoutEdge = 0;

        for (const netNode of data.fillNetNodes) {
            // Skip if the fill node is disabled
            const [fillNodeInNet] = getChildrenOfType(this.activePcbLayoutNodes, netNode.uid, PcbNodeTypes.fill);
            if (!fillNodeInNet?.bakedRules.active) continue;

            const connectedLayers = getConnectedCopperLayers(netNode, this.activePcbLayoutNodes, this.copperLayers);
            for (const layer of connectedLayers) {
                data.planes.push({
                    netId: netNode.uid,
                    layer: this.getLayerOrder(layer),
                    // Don't send any hole data, just send the board outline
                    // The board.boundary.clearance will take care of the spacing from the board edge
                    // We're doing this because DeepPCB claims better results for when
                    // our supplied plane data is just the outline, and they interpret the holes
                    shape: {
                        type: DeepPcbShapeType.polygonWithHoles,
                        outline: boardBoundary.shape.points,
                        holes: [],
                    },
                });
            }

            // Calculated keepout from the net node to the layout node and update maxClearance
            const netClearanceFromLayoutEdge =
                calcKeepoutForNodes(netNode, this.layoutNode, this.ruleCompiler.pcbLayoutSelectorMatching) ?? 0;
            maxClearanceFromLayoutEdge = Math.max(maxClearanceFromLayoutEdge, netClearanceFromLayoutEdge);
        }

        // Update the board clearance with the new maxClearance value
        boardBoundary.clearance = DeepPcbHelpers.toNm(maxClearanceFromLayoutEdge);

        return data;
    }

    private generateWires(): DeepPcbWire[] {
        const layoutPosition = this.layoutNode.bakedRules.positionRelativeToDocument;
        const layoutRotation = this.layoutNode.bakedRules.rotationRelativeToDocument.z;

        const traceNodes = getDescendantsOfType(this.activePcbLayoutNodes, this.layoutNode, PcbNodeTypes.routeSegment);
        return traceNodes.map((traceNode) => {
            const [startVector, endVector] = getRouteSegmentAbsStartEndPos(traceNode, this.activePcbLayoutNodes);
            const unrotatedStartVector = vec2.rotateAround(startVector, layoutPosition, -layoutRotation);
            const unrotatedEndVector = vec2.rotateAround(endVector, layoutPosition, -layoutRotation);
            const start = DeepPcbHelpers.getPoint(unrotatedStartVector, layoutPosition);
            const end = DeepPcbHelpers.getPoint(unrotatedEndVector, layoutPosition);

            return {
                type: "segment",
                netId: traceNode.bakedRules.hostNetId ?? DeepPcbExporter.UNCONNECTED_NET_ID,
                layer: this.getLayerOrder(traceNode.bakedRules.layer),
                start,
                end,
                width: DeepPcbHelpers.toNm(traceNode.bakedRules.size.x),
                protected: true,
            };
        });
    }

    private getClipperShapeFromPrimitive(primitive: Primitive) {
        const polygon = approxFittingPolygon(primitive, 64, this.clipperShapeFactory);
        return this.clipperShapeFactory.shape([polygon]);
    }

    private getPointsFromShape(shape: Primitive, offset = DeepPcbHelpers.origin, rotation = 0) {
        return approxFittingPolygon(shape, 64, this.clipperShapeFactory).map((point) => {
            const rotatedPoint = vec2.rotateAround(point, offset, rotation);
            return DeepPcbHelpers.getPoint(rotatedPoint, offset);
        });
    }

    private generatePadstacksForAllViaConfigs() {
        const viaDefintionsForViaConfigs: string[] = [];

        const {viaConfigs} = getStackupLayersAndViaConfigs(
            {topLevelLayoutUid: this.layoutNode.uid},
            this.activePcbLayoutNodes,
        );
        for (const viaConfig of viaConfigs) {
            if (!viaConfig.enabled) continue;

            const [segment, ...restSegments] = viaConfig.segments;
            if (!segment) continue; // This should ideally not happen

            // We only care about throughHoles for now, and a throughHole config can necessarily only have one segment
            if (restSegments.length > 0) continue;
            const canonicalViaType = getViaSegmentCanonicalType(segment, this.copperLayers);
            if (canonicalViaType !== PcbViaType.throughHole) continue;

            // Now we're dealing with a throughHole config
            const {size} = new RuleParser({size: {key: "size", uid: "size", value: segment.size}}).parseRules(["size"]);
            const radius = DeepPcbHelpers.toNm(size.x / 2);

            const padstackId = viaConfig.uid;
            viaDefintionsForViaConfigs.push(padstackId);

            this.viaDefinitions.push(padstackId);
            this.padstacks[padstackId] ??= {
                id: padstackId,
                layers: this.copperLayers.map((layer) => this.getLayerOrder(layer)),
                allowVia: true,
                shape: {
                    type: DeepPcbShapeType.circle,
                    center: DeepPcbHelpers.getPoint(DeepPcbHelpers.origin),
                    radius,
                },
            };
        }

        return viaDefintionsForViaConfigs;
    }

    private generatePadstackForViaNode(viaNode: PcbBakedNode<PcbNodeTypes.via>): DeepPcbPadstack | undefined {
        // DeepPCB only supports throughHole via type for now, so we need to first determine whether
        // the via is a throughHole via or not. If it is not, we will skip it.
        // TODO: Add support for blind/buried vias when DeepPCB supports it
        // Question: Should we create a keepout for blind/buried vias?
        let canonicalViaType = PcbViaType.throughHole;
        let segmentInfo: ReturnType<typeof getViaSegmentFromType> | undefined;
        if (PcbNodeHelper.isNodeUnderSmartVia(viaNode)) {
            const smartViaParentNode = this.activePcbLayoutNodes[
                viaNode.parentUid
            ] as PcbBakedNode<PcbNodeTypes.smartVia>;
            segmentInfo = getViaSegmentFromType(
                viaNode.bakedRules.viaType,
                smartViaParentNode.bakedRules.selectedViaConfigs,
            );
            if (segmentInfo) {
                canonicalViaType = getViaSegmentCanonicalType(segmentInfo.segment, this.copperLayers);
            }
        }

        // Only create via padstack for through-hole vias
        if (canonicalViaType !== PcbViaType.throughHole) {
            return undefined;
        }

        // Don't create vias/via padstacks for stitching vias
        if (PcbNodeHelper.isVirtualNode(viaNode) && viaNode.virtualNodeInfo.originRule === "fill") {
            return undefined;
        }

        // Now we need to check whether this via is a part of a smartVia throughHole config
        // if yes: we need to check whether the baked size and layers of the node match that of the config
        //         in cases where their size or layers has been changed by an object-specific or important rule
        //         if the match: we'll use the config's uid as the padstackId
        //         if not a match, we'll create a new padstack for it
        // if no:  create or use an existing padstack for the via

        // DeepPCB only supports through-hole vias right now, so we should not consider
        // the smart via's viaSegment to determine the connectivity, since the throughHole
        // can connect to all layers. We will directly use the connectedLayers rule on the via node instead.
        const respectSmartViaConnectedLayers = false;
        const connectedLayers = getConnectedCopperLayers(
            viaNode,
            this.activePcbLayoutNodes,
            this.copperLayers,
            respectSmartViaConnectedLayers,
        );
        const layerOrders = connectedLayers.map((layer) => this.getLayerOrder(layer));

        const viaScaledSize = vec2.scale(viaNode.bakedRules.size, viaNode.bakedRules.scale);
        const viaScaledRadius = viaScaledSize.x / 2;

        // If the via is part of a smartVia throughHole config, we'll use the config's uid as the padstackId
        if (segmentInfo?.viaConfig) {
            const viaConfigUid = segmentInfo.viaConfig.uid;
            const padstackForViaConfig = this.padstacks[viaConfigUid];
            if (padstackForViaConfig) {
                const layersMatch = padstackForViaConfig.layers.every((layerOrder) => layerOrders.includes(layerOrder));
                const radiiMatch =
                    (padstackForViaConfig.shape as DeepPcbShapeCircle).radius === DeepPcbHelpers.toNm(viaScaledRadius);
                if (layersMatch && radiiMatch) {
                    return padstackForViaConfig;
                }
            }
        }

        // The padstackId will be the same for vias with the same layers and radius
        const padstackId = DeepPcbHelpers.hash([layerOrders, viaScaledRadius]);
        if (!padstackId) return undefined; // This should ideally not happen

        // Don't create a duplicate padstack if it already exists. Return the existing one
        const existingPadstack = this.padstacks[padstackId];
        if (existingPadstack) {
            return existingPadstack;
        }

        const padstack: DeepPcbPadstack = {
            id: padstackId,
            layers: layerOrders,
            allowVia: true,
            shape: {
                type: DeepPcbShapeType.circle,
                center: DeepPcbHelpers.getPoint(DeepPcbHelpers.origin),
                radius: DeepPcbHelpers.toNm(viaScaledRadius),
            },
        };
        this.padstacks[padstack.id] = padstack;
        this.viaDefinitions.push(padstack.id);
        return padstack;
    }

    private generatePadShape(padNode: PcbBakedNode<PcbNodeTypes.pad>): DeepPcbShape | undefined {
        const padShapeFromShapeStore = this.nodeShapesMap.get(padNode.uid);
        if (!padShapeFromShapeStore) return undefined;

        const copperShapes = padShapeFromShapeStore.primitives.filter((shape) => shape.role === PrimitiveRole.copper);
        const holeShapes = padShapeFromShapeStore.primitives.filter((shape) => shape.role === PrimitiveRole.layoutHole);

        const padPosition = padNode.bakedRules.positionRelativeToRootLayout!;
        const padRotation = padNode.bakedRules.rotationRelativeToRootLayout!.z;
        // Why are we undoing the rotation? Because DeepPCB will apply the rotation on their end
        const adjustedRotation = -padRotation;

        //
        // Shapes with holes
        //
        if (holeShapes.length) {
            // Create keepout for hole pads with hole size >= pad size
            const holeSize = padNode.bakedRules.hole?.holeSize;
            const size = padNode.bakedRules.size;
            if (holeSize && holeSize.x >= size.x && holeSize.y >= size.y) {
                this.holeTypePadNodes.push(padNode);
                return undefined;
            }

            // Create keepout for hole pads without any copper primitives
            const [copperShape] = copperShapes;
            if (!copperShape) {
                this.holeTypePadNodes.push(padNode);
                return undefined;
            }

            return {
                type: DeepPcbShapeType.polygonWithHoles,
                outline: this.getPointsFromShape(copperShape, padPosition, adjustedRotation),
                holes: holeShapes
                    // Filter out any 0-area holes
                    .map((shape) => {
                        const clipperShape = this.getClipperShapeFromPrimitive(shape);
                        if (clipperShape.totalArea() > 0) {
                            return this.getPointsFromShape(shape, padPosition, adjustedRotation);
                        }
                        return [];
                    })
                    .filter((points) => points.length > 0),
            };
        }

        //
        // Shapes without holes
        //
        const [copperShape, ...restShapes] = copperShapes;
        if (!copperShape) return undefined;
        if (restShapes.length === 0) {
            //
            // Single shape: polygon
            // We want to use polygon instead of DeepPCB's "circle" or "rectangle"
            // primitives to ensure that scale, rotation, or any other mutation is respected
            // Using the PCBShapeStore provides this.
            //
            return {
                type: DeepPcbShapeType.polygon,
                points: this.getPointsFromShape(copperShape, padPosition, adjustedRotation),
            };
        }

        //
        // Multiple primitives
        //
        return {
            type: DeepPcbShapeType.polygon,
            points: copperShapes.flatMap((shape) => this.getPointsFromShape(shape, padPosition, adjustedRotation)),
        };
    }

    private generatePadstackForPadNode(padNode: PcbBakedNode<PcbNodeTypes.pad>): DeepPcbPadstack | undefined {
        const connectedLayers = getConnectedCopperLayers(padNode, this.activePcbLayoutNodes, this.copperLayers, false);
        const layerIds = connectedLayers.map((layer) => this.getLayerOrder(layer));
        if (layerIds.length === 0) return undefined;

        const padShape = this.generatePadShape(padNode);
        if (!padShape) return undefined;

        // Allow only STD/platedThroughHole type pads to have vias
        const padType = padNode.bakedRules.hole?.holeType;
        const allowViaPadTypes = [FootPrintPadHoleType.platedThroughHole];
        const allowVia = padType ? allowViaPadTypes.includes(padType) : false;

        // The padstackId will be the same for pads with the same layerIds, shape, and allowVia
        // This is achieved by hashing the above values and using the hash as the padstackId
        const padstackId = DeepPcbHelpers.hash([layerIds, padShape, allowVia]);
        if (!padstackId) return undefined; // This should ideally not happen

        // Don't create a duplicate padstack if it already exists. Return the existing one
        // This reduces the file size by a lot (e.g. 4mb -> 925kb on a medium-complexity board),
        // but unfornately we _need_ to do the heavy computation of generating padShapes
        // for all pad nodes to enable this optimization
        const existingPadstack = this.padstacks[padstackId];
        if (existingPadstack) {
            return existingPadstack;
        }

        const padstack = {
            id: padstackId,
            layers: layerIds,
            allowVia,
            shape: padShape,
        };
        this.padstacks[padstackId] = padstack;
        return padstack;
    }

    private createDeepPcbPinFromPadNode(
        padNode: PcbBakedNode<PcbNodeTypes.pad>,
        parentNode: PcbBakedNode<PcbNodeTypes.element | PcbNodeTypes.pad>,
    ): DeepPcbPin | undefined {
        // Create keepout for non-plated holes, not pins
        if (padNode.bakedRules.hole?.holeType === FootPrintPadHoleType.nonPlatedHole) {
            this.holeTypePadNodes.push(padNode);
            return undefined;
        }

        const padstack = this.generatePadstackForPadNode(padNode);
        if (!padstack) return undefined;

        // We want the relative position of the _rotated_ pad w.r.t the parent element
        // The offset is the parent element's position, so we subtract it from the pad's position
        // This must be supplied correctly by the caller
        const parentPosition = parentNode.bakedRules.positionRelativeToRootLayout!;
        const position = vec2.sub(padNode.bakedRules.positionRelativeToRootLayout!, parentPosition);
        // Rotation is the absolute rotation of the pad (relative to layout), and not relative to element
        // since we're specifying the rotation of the element to be 0
        const rotation = padNode.bakedRules.rotationRelativeToRootLayout!.z;
        const pinName = DeepPcbHelpers.getUniqueNodeName(padNode);

        return {
            id: pinName,
            padstack: padstack.id,
            // Relative position of the rotated pad w.r.t the parent element
            position: DeepPcbHelpers.getPoint(position),
            rotation: DeepPcbHelpers.getRotation(rotation),
        };
    }

    private createComponentDefinitionAndComponent(
        node: PcbBakedNode<PcbNodeTypes.element | PcbNodeTypes.pad>,
        pins: DeepPcbPin[],
    ) {
        const componentId = DeepPcbHelpers.getUniqueNodeName(node);
        const componentDefinition: DeepPcbComponentDefinition = {
            id: componentId,
            pins,
            keepouts: [],
            // For now, we don't have a reliable way to compute the outline of a component
            outline: undefined,
        };

        const position = node.bakedRules.positionRelativeToRootLayout!;
        const component: DeepPcbComponent = {
            definition: componentId,
            id: componentId,
            partNumber: node.name,
            side: "FRONT",
            position: DeepPcbHelpers.getPoint(position),
            rotation: 0,
        };

        this.createdElementsCache.add(componentId);
        pins.forEach((pin) => this.createdPinsCache.add(pin.id));

        return {componentDefinition, component};
    }

    private recursivelyGetAllElementNodes(
        layoutNode: PcbBakedNode<PcbNodeTypes.layout> = this.layoutNode,
    ): PcbBakedNode<PcbNodeTypes.element>[] {
        const allElementNodes: PcbBakedNode<PcbNodeTypes.element>[] = [];

        const thisLayoutElementNodes = getDescendantsOfType(
            this.activePcbLayoutNodes,
            layoutNode,
            PcbNodeTypes.element,
        );
        for (const elementNode of thisLayoutElementNodes) {
            const sublayoutNodes = getChildrenOfType(this.activePcbLayoutNodes, elementNode.uid, PcbNodeTypes.layout);
            if (sublayoutNodes.length) {
                sublayoutNodes.forEach((sublayoutNode) => {
                    allElementNodes.push(...this.recursivelyGetAllElementNodes(sublayoutNode));
                });
            } else {
                allElementNodes.push(elementNode);
            }
        }

        return allElementNodes;
    }

    private generateComponents(): {
        componentDefinitions: DeepPcbComponentDefinition[];
        components: DeepPcbComponent[];
    } {
        const componentDefinitions: DeepPcbComponentDefinition[] = [];
        const components: DeepPcbComponent[] = [];

        const elementNodes = this.recursivelyGetAllElementNodes();
        for (const elementNode of elementNodes) {
            // Create pins from padnodes, and unique padStacks for all pad nodes to prevent ambiguity
            const padNodes = getDescendantsOfType(this.activePcbLayoutNodes, elementNode, PcbNodeTypes.pad);
            const pins: DeepPcbPin[] = [];
            for (const padNode of padNodes) {
                const pin = this.createDeepPcbPinFromPadNode(padNode, elementNode);
                if (!pin) continue;
                pins.push(pin);
            }

            const {componentDefinition, component} = this.createComponentDefinitionAndComponent(elementNode, pins);
            componentDefinitions.push(componentDefinition);
            components.push(component);
        }

        // For pad nodes that do not have a parent/top-level element node, create a dummy
        // component definition and component to represent them
        const nonElementPadNodes = getTopLevelLayoutNonElementDescendantsPads(
            this.activePcbLayoutNodes,
            this.layoutNode.uid,
            elementNodes,
        );
        for (const padNode of nonElementPadNodes) {
            const pin = this.createDeepPcbPinFromPadNode(padNode, padNode); // Parent node is the pad node itself
            if (!pin) continue;
            const pins = [pin];

            const {componentDefinition, component} = this.createComponentDefinitionAndComponent(padNode, pins);
            componentDefinitions.push(componentDefinition);
            components.push(component);
        }

        return {
            componentDefinitions,
            components,
        };
    }

    private generateVias(): DeepPcbVia[] {
        const vias: DeepPcbVia[] = [];

        const viaNodes = getDescendantsOfType(this.activePcbLayoutNodes, this.layoutNode, PcbNodeTypes.via);
        for (const viaNode of viaNodes) {
            const padstack = this.generatePadstackForViaNode(viaNode);
            if (!padstack) continue;

            vias.push({
                netId: viaNode.bakedRules.hostNetId ?? DeepPcbExporter.UNCONNECTED_NET_ID,
                padstack: padstack.id,
                position: DeepPcbHelpers.getPoint(viaNode.bakedRules.positionRelativeToRootLayout!),
                protected: true,
            });
        }

        return vias;
    }
}
