import {create, StoreApi, UseBoundStore} from "zustand";

export interface PCBStackupModalStore {
    isOpen: boolean;
    open: () => void;
    close: () => void;
}
export type UsePCBStackupModalStore = UseBoundStore<StoreApi<PCBStackupModalStore>>;

export function createPCBStackupModalStore() {
    return create<PCBStackupModalStore>((set) => ({
        isOpen: false,
        open: () => set({isOpen: true}),
        close: () => set({isOpen: false}),
    }));
}
