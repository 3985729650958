import {IChatMessageThread} from "@buildwithflux/models";
import {ChatRepository} from "@buildwithflux/repositories";
import {arrayToMapByUid, Logger} from "@buildwithflux/shared";
import {entries, keys, omitBy} from "lodash";

import {CopilotChatStoreState} from "../useCopilotChatStore";

export function setCurrentDocumentUid(
    set: (fn: (state: CopilotChatStoreState) => void) => void,
    get: () => CopilotChatStoreState,
    chatRepository: ChatRepository,
    logger: Logger,
) {
    return (documentUid: string) => {
        const existingSubscription = get().threadsSubscription;
        if (existingSubscription) {
            existingSubscription();
        }

        // Use a regular `set` outside of the draft scope to safely update the store
        const unsubscribe = chatRepository.subscribeToAllThreads(
            documentUid,
            (addedOrChangedThreads) => {
                set((state) => {
                    const updatedThreadsData = arrayToMapByUid(addedOrChangedThreads);

                    return {
                        ...state,
                        threads: {
                            ...state.threads,
                            ...entries(updatedThreadsData).reduce(
                                (
                                    acc: {
                                        [threadUid: string]: IChatMessageThread;
                                    },
                                    [threadUid, threadData],
                                ) => {
                                    acc[threadUid] = {
                                        ...threadData,
                                        messages: state.threads?.[threadUid]?.messages || {},
                                    };

                                    return acc;
                                },
                                {},
                            ),
                        },
                    };
                });

                if (!get().currentThreadUid) {
                    get().setCurrentThreadToLatest();
                }
            },
            (removedThreads) => {
                set((state) => {
                    const uidsToRemove = keys(removedThreads);

                    return {
                        ...state,
                        threads: omitBy(state.threads, (value) => uidsToRemove.includes(value.uid)),
                    };
                });

                if (!get().currentThreadUid) {
                    get().setCurrentThreadToLatest();
                }
            },
            (error) => {
                logger.error("Error subscribing to chat threads", error);
            },
        );

        set((draft) => {
            if (draft.currentDocumentUid !== documentUid) {
                delete draft.currentThreadUid;
                draft.threads = {};
            }

            draft.currentDocumentUid = documentUid;
            draft.threadsSubscription = unsubscribe;
        });
    };
}
