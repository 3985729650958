import {MessageReaction} from "@buildwithflux/models";
import {ChatRepository} from "@buildwithflux/repositories";
import {Logger} from "@buildwithflux/shared";

import {CurrentUserService} from "../../../../../../../../../../modules/auth";
import {CopilotChatStoreState} from "../useCopilotChatStore";

export function setMessageReaction(
    set: (fn: (state: CopilotChatStoreState) => void) => void,
    get: () => CopilotChatStoreState,
    currentUserService: CurrentUserService,
    chatRepository: ChatRepository,
    logger: Logger,
) {
    return async (threadUid: string, messageUid: string, reaction: MessageReaction) => {
        const {currentDocumentUid, threads} = get();
        if (!currentDocumentUid) return;

        const currentUser = currentUserService.getCurrentUser();
        if (!currentUser) return;

        const message = threads[threadUid]?.messages[messageUid];
        if (!message) return;

        const timestamp = Date.now();

        set((draft) => {
            const draftThread = draft.threads[threadUid];

            if (draftThread) {
                const draftMessage = draftThread.messages[messageUid];
                const draftExistingReaction = draftMessage?.reactions[currentUser.uid];

                if (draftExistingReaction) {
                    draftExistingReaction.reaction = reaction;
                    draftExistingReaction.updated_at = timestamp;
                } else if (draftMessage) {
                    draftMessage.reactions[currentUser.uid] = {
                        uid: currentUser.uid,
                        reaction: reaction,
                        belongs_to_chat_message_uid: messageUid,
                        created_at: timestamp,
                        updated_at: timestamp,
                    };
                }
                draftThread.updated_at = timestamp;
            }
        });

        try {
            const reaction = get().threads[threadUid]?.messages[messageUid]?.reactions[currentUser.uid];
            if (!reaction) return;

            // Persist the reaction to the backend
            await chatRepository.setReaction(currentDocumentUid, threadUid, message.uid, reaction);
        } catch (error) {
            logger.error("Failed to set message reaction:", error);
        }
    };
}

export function removeMessageReaction(
    set: (fn: (state: CopilotChatStoreState) => void) => void,
    get: () => CopilotChatStoreState,
    currentUserService: CurrentUserService,
    chatRepository: ChatRepository,
    logger: Logger,
) {
    return async (threadUid: string, messageUid: string) => {
        const {currentDocumentUid, threads} = get();
        if (!currentDocumentUid) return;

        const currentUser = currentUserService.getCurrentUser();
        if (!currentUser) return;

        const reaction = threads[threadUid]?.messages[messageUid]?.reactions[currentUser.uid];
        if (!reaction) return;

        set((draft) => {
            const draftThread = draft.threads[threadUid];
            const draftMessage = draftThread?.messages[messageUid];

            if (draftThread && draftMessage) {
                delete draftMessage.reactions[currentUser.uid];
                draftThread.updated_at = new Date().getTime();
            }
        });

        try {
            // delete the reaction on the backend
            await chatRepository.deleteReaction(currentDocumentUid, threadUid, messageUid, reaction.uid);
        } catch (error) {
            logger.error("Failed to delete reaction:", error);
        }
    };
}
