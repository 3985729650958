import {
    AbstractFluxLogger,
    AnyPcbBakedNode,
    ClipperShape,
    ClipperShapeFactory,
    DocumentSubCollectionChange,
    FootPrintPadHoleType,
    INetHashMap,
    PcbBakedNode,
    PcbLayoutFootprintPadShape,
    PcbNodeHelper,
    PcbNodeTypes,
    PcbNodesMap,
    PcbViaType,
    ShapesMap,
    SubscriptionManager,
    UnknownError,
    ZeroEuler,
    ZeroVector3,
    connectivityTolerance,
    createShapeFromBakedRules,
    filterTruthy,
    seg2,
} from "@buildwithflux/core";
import {SubscribedTypeCallback} from "@buildwithflux/repositories";
import {Logger} from "@buildwithflux/shared";
import {UndirectedGraph} from "graphology";
import {connectedComponents} from "graphology-components";
import {Attributes} from "graphology-types";
import {pick} from "lodash";
import RBush from "rbush";
import {Vector2, Vector3} from "three";

import {eulerFromXYZ, vector3FromXYZ} from "../../helpers/ThreeJSHelper";
import {FluxLogger} from "../storage_engine/connectors/LogConnector";

import {calcCopperFills, getAllFillShapes} from "./copperFills";
import {
    GraphEdgeAttributes,
    UnitNodeAttributes,
    FillNodeAttributes,
    GraphNodeAttributes,
    GraphNodeType,
} from "./models";
import {pointsAlongCustomShape, pointsAlongOblong, pointsAlongRectangle} from "./utils";

// TODO: Check `connectivityTollerence`, replace this with shape-to-shape collision
// Recently changed from 100nm to 200nm because of numerical errors
// Notes from Giulio: right now we are using center-to-center distance as a way to determine connectivity,
// which works but can have problems. One day we will replace this with shape-to-shape collision.

/** N of sampling test points for fill-pad/via intersection, see below */
const nTestPoints = 8;

export type UpdateGraphInputs = {
    pcbLayoutNodes: PcbNodesMap<AnyPcbBakedNode>;
    netMap: INetHashMap;
    clipperShapeFactory: ClipperShapeFactory;
    shapesMap: ShapesMap<Vector2>;
    bakedNodesChanges?: DocumentSubCollectionChange["payload"]["pcbLayoutNodes"];
};

// Internal helpers
const isNodeConnectedToFill = (node: UnitNodeAttributes, fillNode: FillNodeAttributes) => {
    return node.testPoints.some((testPoint) => fillNode.shape.pointInSubPolysFast(testPoint) !== undefined);
};
const areNodesConnected = (node1: UnitNodeAttributes, node2: UnitNodeAttributes) => {
    const isLine1 = node1.type === GraphNodeType.routeNodeLine;
    const isLine2 = node2.type === GraphNodeType.routeNodeLine;

    // Line-Line
    if (isLine1 && isLine2) {
        return seg2.intersection(node1.line, node2.line) !== undefined;
    }

    // Line-Point
    if (isLine1 && !isLine2) {
        return seg2.pointOnSegment(node2.position, node1.line, connectivityTolerance);
    }

    // Point-Line
    if (isLine2 && !isLine1) {
        return seg2.pointOnSegment(node1.position, node2.line, connectivityTolerance);
    }

    // Point-Point
    if (!isLine1 && !isLine2) {
        return node1.position.distanceTo(node2.position) <= connectivityTolerance;
    }

    // Invalid case
    return false;
};
const areNodesInSameLayer = (node1: GraphNodeAttributes, node2: GraphNodeAttributes) => {
    return (
        node1.connectedLayers === "All" ||
        node2.connectedLayers === "All" ||
        node1.connectedLayers.some((lA) => node2.connectedLayers.includes(lA))
    );
};

export class PcbConnectivityGraph {
    /** A graph with all the pcb copper elements and their current physical connection (not the expected ones from schematic) */
    private graph: UndirectedGraph<GraphNodeAttributes, GraphEdgeAttributes, Attributes>;

    /** Keeps track of connected components in the connectivity graph, as a list of connected graph uids */
    private connectedComponents: string[][] = [];

    /** The connected components associated to each net, used later to compute airwires */
    private netToConnectedComponents: Record<string, string[][]> = {};

    /** For now we support only global subscriptions, but if needed we can support granular subscriptions in the future */
    private readonly subscriptionManager = new SubscriptionManager<"update", PcbConnectivityGraph>();

    /** To solve race conditions with the baked document data being more updated than this store */
    private isDataUpdated = false;

    constructor() {
        // We build a graph with
        // - Pads, vias and trace endpoints as nodes
        // - Traces and found connections as edges
        this.graph = new UndirectedGraph();
    }

    public updateGraph(inputs: UpdateGraphInputs, logger: AbstractFluxLogger | Logger): void {
        const {bakedNodesChanges} = inputs;

        this.isDataUpdated = true;

        // Only node changes or net changes need to update the graph
        if (!bakedNodesChanges) return;

        let nodesToSet = Array.from(
            new Set([
                ...(bakedNodesChanges?.set ?? []),
                ...(bakedNodesChanges?.add ?? []),
                ...(bakedNodesChanges?.replace ?? []),
            ]),
        );
        // HACK: If any net nodes are changed, we need to recalculate all nodes
        // because this could potentially impact netUid under many nodes, or the
        // connected layers of a net could have changed.
        // NOTE: This means it is not a fully incremental update if net nodes
        // are changed, and we hereby treat ALL nodes as updated nodes because
        // we don't know what nodes are affected...
        // TODO: FLUX-5054 Make this incremental if possible. The main blocker here is
        // that `netMap` is always calculated from scratch upstream. Its a
        // derived data of existing nodes.
        const netNodesChanged = Object.values(pick(inputs.pcbLayoutNodes, nodesToSet)).filter(
            (node) => node.type === PcbNodeTypes.net,
        );
        if (netNodesChanged.length > 0) {
            nodesToSet = Object.keys(inputs.pcbLayoutNodes);
        }

        const inactiveNodes = nodesToSet.filter((nodeUid) => {
            return !inputs.pcbLayoutNodes[nodeUid]?.bakedRules?.active;
        });

        // NOTE: If we see that we are trying to reset all baked nodes, that indicates
        // a full rebake of the entire board. In this case, it is faster to rebuild the
        // graph from scratch because in `updateConnectivityByDistance` we have logic
        // to check if any "explicit" are invalid, which is expensive; and in this case,
        // it is a waste of time to check that because we need to rebuild the entire
        // graph anyways.
        if (nodesToSet.length === Object.keys(inputs.pcbLayoutNodes).length) {
            this.graph.clear();
        }

        // Handle remove node operation
        if (bakedNodesChanges?.remove && bakedNodesChanges?.remove.length > 0) {
            this.removeNodes(bakedNodesChanges.remove);
        }

        // Handle inactive nodes (i.e. nodes whose "Enabled" rule is false)
        this.removeNodes(inactiveNodes);

        // TODO: `fillShapesByNetLayer` is only used to update the connectivity graph, and is not incremental.
        // We should try re-use the shapesMap to avoid this calculation.
        // See https://github.com/buildwithflux/flux-app/issues/8850
        // Copper fills
        const fillShapesByNetLayer = calcCopperFills(inputs);

        // Handle add/set node operation, we only care about active nodes
        const activeNodesToSet = nodesToSet.filter((nodeUid) => {
            return inputs.pcbLayoutNodes[nodeUid]?.bakedRules?.active;
        });
        const {newNodes, newEdges} = this.generateGraphObjects(inputs, fillShapesByNetLayer, activeNodesToSet, logger);

        // Construct the RTree by loading all existing + new graph nodes to it
        const tree = this.constructRTree(newNodes);

        // Incrementally set new graph nodes/edges in graph
        this.setGraphObjects(newNodes, newEdges);

        this.updateConnectivityByDistance(newNodes, tree);

        // =================================================================
        // CONNECTED COMPONENTS
        // TODO: do this incrementally
        this.connectedComponents = connectedComponents(this.graph);

        const netMapArray = Object.entries(inputs.netMap);

        // The connected components associated to each net, used later to compute airwires
        this.netToConnectedComponents = Object.fromEntries(
            netMapArray.map(([netId, terminalIdsObj]) => {
                // HACK: it's possible that for some reason the net map contains uids that are not pcbLayoutNodes
                // For example, sometimes for the GND net the uid of the gnd schematic object is present
                const terminalIds = Object.keys(terminalIdsObj).filter((id) => inputs.pcbLayoutNodes[id]);
                // For every net, every terminal should be part of the same connected component
                const connectedComponentsRelatedToNet = Array.from(
                    new Set(
                        filterTruthy(
                            terminalIds.map((terminalId) =>
                                this.connectedComponents.find((cc) => cc.includes(terminalId)),
                            ),
                        ),
                    ),
                );
                return [netId, connectedComponentsRelatedToNet];
            }),
        );

        // =================================================================
        // SUBSCRIPTIONS
        this.subscriptionManager.notify("update", this);
    }

    /**
     * Returns the latest graph.
     *
     * TODO: This is not good tho because this means one could accidentally mutate the graph via
     * this method, without going through this class... However, I dont think of a good way to
     * improve this yet. One thought is to restrict the returned type here to only Pick read methods
     * from the Graph class, but thast a huge amount of methods...
     * Tracked by ticket: https://github.com/buildwithflux/flux-app/issues/8955
     */
    public getGraph(): Readonly<UndirectedGraph<GraphNodeAttributes, GraphEdgeAttributes, Attributes>> | undefined {
        if (!this.isDataUpdated) return undefined;
        return this.graph;
    }

    /**
     * It is recommended to call this method to retrieve a graph node because it first checks
     * existense for you. Otherwise, calling `getNodeAttributes` directly could throw error if
     * node doesn't exist
     */
    public getNodeAttributes(nodeId: string): Readonly<GraphNodeAttributes> | null {
        if (!this.isDataUpdated) return null;
        if (!this.graph.hasNode(nodeId)) return null;

        return this.graph.getNodeAttributes(nodeId);
    }

    public getConnectedComponents(): Readonly<string[][]> | undefined {
        if (!this.isDataUpdated) return undefined;
        return this.connectedComponents;
    }

    public getNetToConnectedComponents(): Readonly<Record<string, string[][]>> | undefined {
        if (!this.isDataUpdated) return undefined;
        return this.netToConnectedComponents;
    }

    public subscribe(callback: SubscribedTypeCallback<PcbConnectivityGraph>) {
        return this.subscriptionManager.addSubscription("update", callback);
    }

    public clear() {
        this.graph.clear();
        this.connectedComponents = [];
        this.netToConnectedComponents = {};
        this.isDataUpdated = false;
    }

    public invalidateGraph() {
        this.isDataUpdated = false;
    }

    /**
     * On every update of the graph, we construct the RBush by feeding in all
     * existing graph nodes + new graph nodes.
     *
     * We do so instead of incrementally update it because bulk insert is more performant
     * according to their doc: https://github.com/mourner/rbush#bulk-inserting-data
     */
    private constructRTree(newNodes: GraphNodeAttributes[]) {
        const tree = new RBush<GraphNodeAttributes>();
        const allGraphNodes: GraphNodeAttributes[] = [...newNodes];
        this.graph.forEachNode((_node, attributes) => allGraphNodes.push(attributes));
        tree.load(allGraphNodes);

        return tree;
    }

    /**
     * Remove any nodes/edges from graph. Note that `dropNode` also drops any edges
     * connected to the dropped node
     */
    private removeNodes(removeNodeUids: string[]) {
        removeNodeUids.forEach((removedNodeUid) => {
            // Handle Pad/Via removal
            if (this.graph.hasNode(removedNodeUid)) {
                this.graph.dropNode(removedNodeUid);
            }

            // Handle RouteSegment removal. Removing nodes will also remove the connected edges
            const routeEndpointNodeUids = [
                `${removedNodeUid}_start`,
                `${removedNodeUid}_end`,
                `${removedNodeUid}_line`,
            ];
            routeEndpointNodeUids.forEach((routeEndpointNodeUid) => {
                if (this.graph.hasNode(routeEndpointNodeUid)) {
                    this.graph.dropNode(routeEndpointNodeUid);
                }
            });
        });
    }

    // For each graph node, we try to find the connected stuff by using the distance between them (including layers)
    // For example traces that ends directly on top of pads or two trace extremes
    private updateConnectivityByDistance(newGraphNodes: GraphNodeAttributes[], tree: RBush<GraphNodeAttributes>) {
        const searchBB = {minX: 0, minY: 0, maxX: 0, maxY: 0};

        newGraphNodes.forEach((graphNode) => {
            searchBB.minX = graphNode.minX - connectivityTolerance;
            searchBB.minY = graphNode.minY - connectivityTolerance;
            searchBB.maxX = graphNode.maxX + connectivityTolerance;
            searchBB.maxY = graphNode.maxY + connectivityTolerance;

            // We don't care about fill=>pad, but only pad=>fill (simpler and equivalent anyways)
            if (graphNode.type === GraphNodeType.fill) return null;

            // Loop through all neighbors connected to the node, remove any invalid "explicit". This should
            // be a negate of the following blog of code which adds "explicit" to the graph
            this.graph.forEachNeighbor(graphNode.uid, (neighborKey, neighborAttributes) => {
                // Edge must exist at this point so no need to check existence
                const edge = this.graph.getEdgeAttributes(graphNode.uid, neighborKey);
                // We only care about "explicit" here.
                if (edge.type !== "explicit") return;

                // If not in the same layer, drop the edge
                if (!areNodesInSameLayer(graphNode, neighborAttributes)) {
                    this.graph.dropEdge(graphNode.uid, neighborKey);
                }

                if (neighborAttributes.type === GraphNodeType.fill) {
                    if (!isNodeConnectedToFill(graphNode, neighborAttributes)) {
                        this.graph.dropEdge(graphNode.uid, neighborKey);
                    }
                } else {
                    if (!areNodesConnected(neighborAttributes, graphNode)) {
                        this.graph.dropEdge(graphNode.uid, neighborKey);
                    }
                }
            });

            // Add "explicit" to connect nodes in graph, based on layer and distance
            tree.search(searchBB).forEach((graphNode2) => {
                if (graphNode.uid === graphNode2.uid) return;
                if (graphNode2.type !== GraphNodeType.fill && graphNode.pcbNodeUid === graphNode2.pcbNodeUid) return;

                if (!areNodesInSameLayer(graphNode, graphNode2)) return;

                const shouldAdd =
                    graphNode2.type === GraphNodeType.fill
                        ? isNodeConnectedToFill(graphNode, graphNode2)
                        : areNodesConnected(graphNode2, graphNode);

                const startUid = graphNode.uid;
                const endUid = graphNode2.uid;
                if (shouldAdd && !this.graph.hasEdge(startUid, endUid)) {
                    this.graph.addEdge(startUid, endUid, {type: "explicit" as const, startUid, endUid});
                }
            });
        });
    }

    /**
     * Helper function to set graph nodes/edges to the graph
     */
    private setGraphObjects(newNodes: GraphNodeAttributes[], newEdges: GraphEdgeAttributes[]) {
        newNodes.forEach((node) => {
            // NOTE: `mergeNode` handles both add/update cases
            // "Adds a node only if the node does not exist in the graph yet. Else it will merge the provided attributes with the already existing ones."
            this.graph.mergeNode(node.uid, node);
        });
        newEdges.forEach((edge) => {
            if (!this.graph.hasEdge(edge.startUid, edge.endUid)) {
                this.graph.addEdge(edge.startUid, edge.endUid, edge);
            }
        });
    }

    /**
     * Given the DRC input, generate all graph objects we will add to PcbConnectivityGraph, they
     * include both GraphNodes and GraphEdges
     */
    private generateGraphObjects(
        inputs: UpdateGraphInputs,
        fillShapesByNetLayer: Record<string, ClipperShape>,
        updatedNodeUids: string[],
        logger: AbstractFluxLogger | Logger,
    ) {
        // First generate all the graph nodes/edges from inputs
        const {routeEndpointGraphNodes, routeLineGraphNodes, routeGraphEdges} =
            this.generateRouteSegmentGraphNodesAndEdges(inputs.pcbLayoutNodes, updatedNodeUids);
        const viaGraphNodes = this.generateViaGraphNodes(inputs.pcbLayoutNodes, updatedNodeUids);
        const padGraphNodes = this.generatePadGraphNodes(inputs.pcbLayoutNodes, inputs.netMap, updatedNodeUids, logger);
        const fillGraphNodes = this.generateFillGraphNodes(
            inputs.clipperShapeFactory,
            inputs.pcbLayoutNodes,
            fillShapesByNetLayer,
        );

        const newGraphNodes: GraphNodeAttributes[] = [
            ...padGraphNodes,
            ...routeEndpointGraphNodes,
            ...routeLineGraphNodes,
            ...viaGraphNodes,
            ...fillGraphNodes,
        ];
        const newGraphEdges: GraphEdgeAttributes[] = routeGraphEdges;

        return {
            newNodes: newGraphNodes,
            newEdges: newGraphEdges,
        };
    }

    private generateRouteSegmentGraphNodesAndEdges(
        pcbNodes: UpdateGraphInputs["pcbLayoutNodes"],
        updatedNodeUids: string[],
    ) {
        // RouteSegment nodes
        // Let's collect info about our traces: every trace is two graph nodes and an arc
        const routeEndpointGraphNodes: GraphNodeAttributes<GraphNodeType.routeNodeEndpoint>[] = [];
        const routeLineGraphNodes: GraphNodeAttributes<GraphNodeType.routeNodeLine>[] = [];
        const routeGraphEdges: GraphEdgeAttributes[] = [];

        const updatedRouteSegmentNodes = updatedNodeUids
            .map((uid) => pcbNodes[uid])
            .filter(
                (node): node is PcbBakedNode<PcbNodeTypes.routeSegment> => node?.type === PcbNodeTypes.routeSegment,
            );

        updatedRouteSegmentNodes.forEach((node) => {
            const rs = node;
            const absolutePos = vector3FromXYZ(rs.bakedRules.positionRelativeToDocument);
            const absoluteRot = eulerFromXYZ(rs.bakedRules.rotationRelativeToDocument ?? ZeroEuler);
            const layoutRelPos = vector3FromXYZ(rs.bakedRules?.positionRelativeToRootLayout ?? ZeroVector3);
            const layoutRelRot = eulerFromXYZ(rs.bakedRules?.rotationRelativeToRootLayout ?? ZeroEuler);
            const start = vector3FromXYZ(rs.bakedRules.startPosition);
            const end = vector3FromXYZ(rs.bakedRules.endPosition);
            const size = vector3FromXYZ(rs.bakedRules.size);
            const layer = rs.bakedRules.layer;
            const hasAllInfo = absolutePos && start && end && size && layoutRelPos && layoutRelRot;
            if (!hasAllInfo) {
                // noop
            } else {
                const absStart = start.clone().applyEuler(absoluteRot).add(absolutePos);
                const absEnd = end.clone().applyEuler(absoluteRot).add(absolutePos);
                const relStart = start.clone().applyEuler(layoutRelRot).add(layoutRelPos);
                const relEnd = end.clone().applyEuler(layoutRelRot).add(layoutRelPos);
                const capRadius = size.x / 2;

                // Having NaN values breaks airwires completely
                // TODO: Revisit when rules parser has been fixed
                if (isNaN(absStart.x) || isNaN(absStart.y) || isNaN(absStart.z) || isNaN(capRadius)) return null;

                // On sublayout we can have multiple touchpoints at the same location with different nets
                // We want to route only from nets we own, aka nets without the __ separator
                const isNodeUnderSubLayout = Boolean(
                    rs.bakedRules.hostNetId && PcbNodeHelper.isNodeUnderSubLayout(rs.bakedRules.hostNetId),
                );

                // Start endpoint node
                const startEndpointNodeUid = rs.uid + "_start";
                routeEndpointGraphNodes.push({
                    type: GraphNodeType.routeNodeEndpoint as const,
                    uid: startEndpointNodeUid,
                    pcbNodeUid: rs.uid,
                    position: absStart,
                    connectedLayers: [layer],
                    testPoints: [ClipperShape.normalizePoint(new Vector2(relStart.x, relStart.y))],
                    noRouting: isNodeUnderSubLayout,
                    netUid: rs.bakedRules.hostNetId,

                    minX: absStart.x - capRadius,
                    minY: absStart.y - capRadius,
                    maxX: absStart.x + capRadius,
                    maxY: absStart.y + capRadius,
                });

                // End endpoint node
                const endEndpointNodeUid = rs.uid + "_end";
                routeEndpointGraphNodes.push({
                    type: GraphNodeType.routeNodeEndpoint as const,
                    uid: endEndpointNodeUid,
                    position: absEnd,
                    pcbNodeUid: rs.uid,
                    connectedLayers: [layer],
                    testPoints: [ClipperShape.normalizePoint(new Vector2(relEnd.x, relEnd.y))],
                    noRouting: isNodeUnderSubLayout,
                    netUid: rs.bakedRules.hostNetId,

                    minX: absEnd.x - capRadius,
                    minY: absEnd.y - capRadius,
                    maxX: absEnd.x + capRadius,
                    maxY: absEnd.y + capRadius,
                });

                // Line node
                const lineNodeUid = rs.uid + "_line";
                routeLineGraphNodes.push({
                    type: GraphNodeType.routeNodeLine as const,
                    uid: lineNodeUid,
                    pcbNodeUid: rs.uid,
                    connectedLayers: [layer],
                    // No need to have test points for lines, the endpoint test points are enough (when checking against fills)
                    testPoints: [],
                    noRouting: isNodeUnderSubLayout,
                    netUid: rs.bakedRules.hostNetId,
                    line: [absStart, absEnd] as [Vector3, Vector3],

                    minX: Math.min(absStart.x, absEnd.x) - capRadius,
                    minY: Math.min(absStart.y, absEnd.y) - capRadius,
                    maxX: Math.max(absStart.x, absEnd.x) + capRadius,
                    maxY: Math.max(absStart.y, absEnd.y) + capRadius,
                });

                // Intra-endpoints Edge
                routeGraphEdges.push({
                    type: "implicit" as const,
                    uid: rs.uid,
                    startUid: startEndpointNodeUid,
                    endUid: endEndpointNodeUid,
                });

                // Endpoints-Line Edges
                routeGraphEdges.push({
                    type: "implicit" as const,
                    startUid: lineNodeUid,
                    endUid: startEndpointNodeUid,
                });
                routeGraphEdges.push({
                    type: "implicit" as const,
                    startUid: lineNodeUid,
                    endUid: endEndpointNodeUid,
                });
            }
        });

        return {
            routeEndpointGraphNodes,
            routeLineGraphNodes,
            routeGraphEdges,
        };
    }

    private generateViaGraphNodes(
        pcbNodes: UpdateGraphInputs["pcbLayoutNodes"],
        updatedNodeUids: string[],
    ): GraphNodeAttributes<GraphNodeType.via>[] {
        // Via nodes - they are just a single node
        const viaGraphNodes: GraphNodeAttributes<GraphNodeType.via>[] = [];
        const updatedViaNodes = updatedNodeUids
            .map((uid) => pcbNodes[uid])
            .filter((node): node is PcbBakedNode<PcbNodeTypes.via> => node?.type === PcbNodeTypes.via);
        updatedViaNodes.forEach((node) => {
            const via = node;
            const absolutePos = vector3FromXYZ(via.bakedRules?.positionRelativeToDocument);
            const layoutRelPos = vector3FromXYZ(via.bakedRules?.positionRelativeToRootLayout ?? ZeroVector3);
            const layoutRelRot = eulerFromXYZ(via.bakedRules?.rotationRelativeToRootLayout ?? ZeroEuler);
            const size = vector3FromXYZ(via.bakedRules?.size);
            const connectedLayers = via.bakedRules.connectedLayers;
            if (!absolutePos || !size || !connectedLayers) {
                // noop
            } else {
                const maxHalfSize = Math.max(size.x, size.y) / 2;
                const isSingleLayer = via.bakedRules.viaType === PcbViaType.microVia;
                const layer = via.bakedRules.layer;
                const connectedLayersNorm = isSingleLayer
                    ? [layer]
                    : connectedLayers[0] === "All"
                    ? "All"
                    : connectedLayers;
                const testPoints = layoutRelPos
                    ? pointsAlongOblong(layoutRelPos, layoutRelRot, size, connectivityTolerance, nTestPoints)
                    : [];

                // Having NaN values breaks airwires completely
                // TODO: Revisit when rules parser has been fixed
                if (isNaN(absolutePos.x) || isNaN(absolutePos.y) || isNaN(absolutePos.z) || isNaN(maxHalfSize)) {
                    // no op
                } else {
                    // On sublayout we can have multiple touchpoints at the same location with different nets
                    // We want to route only from nets we own, aka nets without the __ separator
                    const isNodeUnderSubLayout = Boolean(
                        via.bakedRules.hostNetId && PcbNodeHelper.isNodeUnderSubLayout(via.bakedRules.hostNetId),
                    );

                    viaGraphNodes.push({
                        type: GraphNodeType.via as const,
                        uid: via.uid,
                        pcbNodeUid: via.uid,
                        position: absolutePos,
                        connectedLayers: connectedLayersNorm,
                        testPoints: testPoints.map((tp) => ClipperShape.normalizePoint(tp)),
                        minX: absolutePos.x - maxHalfSize / 2,
                        minY: absolutePos.y - maxHalfSize / 2,
                        maxX: absolutePos.x + maxHalfSize / 2,
                        maxY: absolutePos.y + maxHalfSize / 2,
                        noRouting: isNodeUnderSubLayout,
                        netUid: via.bakedRules.hostNetId,
                    });
                }
            }
        });

        return viaGraphNodes;
    }

    private generatePadGraphNodes(
        pcbNodes: UpdateGraphInputs["pcbLayoutNodes"],
        netMap: UpdateGraphInputs["netMap"],
        updatedNodeUids: string[],
        logger: AbstractFluxLogger | Logger,
    ): GraphNodeAttributes<GraphNodeType.pad>[] {
        const updatedPadNodes = updatedNodeUids
            .map((uid) => pcbNodes[uid])
            .filter((node): node is PcbBakedNode<PcbNodeTypes.pad> => node?.type === PcbNodeTypes.pad);

        const padGraphNodes: GraphNodeAttributes<GraphNodeType.pad>[] = [];

        updatedPadNodes.forEach((node) => {
            const pad = node;
            const absolutePos = vector3FromXYZ(pad.bakedRules?.positionRelativeToDocument);
            const layoutRelPos = vector3FromXYZ(pad.bakedRules?.positionRelativeToRootLayout ?? ZeroVector3);
            const layoutRelRot = eulerFromXYZ(pad.bakedRules?.rotationRelativeToRootLayout ?? ZeroEuler);
            const size = vector3FromXYZ(pad.bakedRules?.size);
            const connectedLayers = pad.bakedRules.connectedLayers;
            if (!absolutePos || !size || !connectedLayers) {
                // noop
            } else {
                const maxHalfSize = Math.max(size.x, size.y) / 2;
                const isSingleLayer =
                    !pad.bakedRules.hole ||
                    pad.bakedRules.hole.holeType === FootPrintPadHoleType.SurfaceMountDevice ||
                    pad.bakedRules.hole.holeType === FootPrintPadHoleType.testPinOrCardEdgeConnector;
                const isPlatedTH =
                    pad.bakedRules.hole && pad.bakedRules.hole.holeType === FootPrintPadHoleType.platedThroughHole;
                if (!isPlatedTH && !isSingleLayer) {
                    // noop
                } else {
                    const layer = pad.bakedRules.layer;
                    const connectedLayersNorm = isSingleLayer
                        ? [layer]
                        : connectedLayers[0] === "All"
                        ? "All"
                        : connectedLayers;

                    const testPoints: Vector2[] = [];
                    if (layoutRelPos) {
                        // Let's start with the center as test point
                        testPoints.push(new Vector2(layoutRelPos.x, layoutRelPos.y));
                        if (
                            pad.bakedRules.asset ||
                            (pad.bakedRules.padShape !== PcbLayoutFootprintPadShape.circular &&
                                pad.bakedRules.padShape !== PcbLayoutFootprintPadShape.rectangle)
                        ) {
                            try {
                                const copperShape = createShapeFromBakedRules(pad.bakedRules, -connectivityTolerance, {
                                    logger: FluxLogger,
                                });

                                if (copperShape) {
                                    testPoints.push(...pointsAlongCustomShape(layoutRelPos, layoutRelRot, copperShape));
                                }
                            } catch (error) {
                                if ("captureError" in logger) {
                                    logger.captureError(new UnknownError(`Error loading shape`, error));
                                } else {
                                    logger.error(`Error loading shape`, error);
                                }
                            }
                        } else if (pad.bakedRules.padShape === PcbLayoutFootprintPadShape.circular) {
                            // If hole and circle, sample points around the annular ring oblong
                            testPoints.push(
                                ...pointsAlongOblong(
                                    layoutRelPos,
                                    layoutRelRot,
                                    size,
                                    connectivityTolerance,
                                    nTestPoints,
                                ),
                            );
                        } else if (pad.bakedRules.padShape === PcbLayoutFootprintPadShape.rectangle) {
                            // If hole and rectangle, use the 4 corners
                            testPoints.push(
                                ...pointsAlongRectangle(layoutRelPos, layoutRelRot, size, connectivityTolerance),
                            );
                        }
                        // TODO: octagonal and polygonal pads
                    }

                    // Having NaN values breaks airwires completely
                    // TODO: Revisit when rules parser has been fixed
                    if (isNaN(absolutePos.x) || isNaN(absolutePos.y) || isNaN(absolutePos.z) || isNaN(maxHalfSize)) {
                        // noop
                    } else {
                        const netMapArray = Object.entries(netMap);
                        // Pads don't necessarily have an associated net, but we can still get one if available from the net map
                        // This will still allow us to exclude them from routing if needed (eg: if they are under sublayouts)
                        const netUid = netMapArray.find(([, terminals]) => terminals[pad.uid])?.[0];

                        // On sublayout we can have multiple touchpoints at the same location with different nets
                        // We want to route only from nets we own, aka nets without the __ separator
                        const isNodeUnderSubLayout = Boolean(netUid && PcbNodeHelper.isNodeUnderSubLayout(netUid));

                        padGraphNodes.push({
                            type: GraphNodeType.pad as const,
                            uid: pad.uid,
                            pcbNodeUid: pad.uid,
                            position: absolutePos,
                            connectedLayers: connectedLayersNorm,
                            testPoints: testPoints.map((tp) => ClipperShape.normalizePoint(tp)),
                            minX: absolutePos.x - maxHalfSize / 2,
                            minY: absolutePos.y - maxHalfSize / 2,
                            maxX: absolutePos.x + maxHalfSize / 2,
                            maxY: absolutePos.y + maxHalfSize / 2,
                            noRouting: isNodeUnderSubLayout || !netUid,
                            netUid,
                        });
                    }
                }
            }
        });

        return padGraphNodes;
    }

    private generateFillGraphNodes(
        clipperShapeFactory: UpdateGraphInputs["clipperShapeFactory"],
        pcbNodes: UpdateGraphInputs["pcbLayoutNodes"],
        fillShapesByNetLayer: Record<string, ClipperShape>,
    ) {
        // TODO: This is not incremental update yet and it will become the most time-consuming step after
        // we make PcbConnectivityGraph incremental. We plan to improve this by re-using polygon information
        // we stored in `useShapesMap`, see item: https://github.com/buildwithflux/flux-app/issues/8850
        return getAllFillShapes(clipperShapeFactory, pcbNodes, fillShapesByNetLayer);
    }
}
