/* eslint-disable id-blacklist */
import {defaultPropertyDefinitions} from "@buildwithflux/constants";
import {
    AnyPcbBakedNode,
    AnyPcbNode,
    areOldProperties,
    ElementHelper,
    getDescendantsOfType,
    getDisplayed5DigitChangeId,
    getPropertyValue,
    isDescendantOfLayout,
    IUserData,
    LayerOrientation,
    naturalSortByProperty,
    PcbBakedNode,
    PcbNodeHelper,
    PcbNodesMap,
    PcbNodeTypes,
    stringifyPropertyValue,
} from "@buildwithflux/core";
import {IDocumentData, IElementsMap, IVector2} from "@buildwithflux/models";
import {
    metersToMm,
    normalizeDegreeOrientation,
    normalizeRadians,
    radiansToDegrees,
    roundTo,
} from "@buildwithflux/shared";
import {ExportToCsv} from "export-to-csv";
import fileDownload from "js-file-download";
import JSZip from "jszip";
import {mapKeys, pick} from "lodash";
import {Box3, Vector3} from "three";

import {eulerFromXYZ, invertEuler, vector3FromXYZ} from "../../../../helpers/ThreeJSHelper";
import {fixAngleDecimals} from "../../../../helpers/we-are-hacks-get-rid-of-us-asap";
import {BaseExporter} from "../BaseExporter";

const Formats = {
    OpenPNP: {
        // Based on https://github.com/openpnp/openpnp/wiki/Importing-Centroid-Data#others
        // and Riley Porter's KiCad export screenshot at
        // https://feedback.flux.ai/admin/feedback/featurerequests/p/openpnp-export-csv-file-support
        columns: {
            Designator: "Ref",
            Value: "Val",
            Package: "Package",
            "Mid X": "PosX",
            "Mid Y": "PosY",
            Rotation: "Rot",
            Layer: "Side",
        },
    },
    JLCPCB: {
        columns: {
            Designator: "Designator",
            Layer: "Layer",
            Rotation: "Rotation",
            "Mid X": "Mid X",
            "Mid Y": "Mid Y",
        },
    },
};

type IDataRow = {
    Designator: string;
    Layer: "Top" | "Bottom";
    Rotation: number;
    "Mid X": string;
    "Mid Y": string;
    Package: string;
    Value: string;
};

export class PickAndPlaceExporter extends BaseExporter {
    private readonly pcbLayoutNodes: PcbNodesMap<AnyPcbBakedNode>;
    private readonly elements: IElementsMap;

    constructor(documentData: IDocumentData, documentOwner: IUserData, pcbLayoutNodes: PcbNodesMap<AnyPcbBakedNode>) {
        super(documentData, documentOwner);
        this.pcbLayoutNodes = pcbLayoutNodes;
        this.elements = documentData.elements;
    }

    // QUESTION: should we also filter by isValidPickAndPlaceElement
    public static hasThingsToExport(pcbLayoutNodesMap: PcbNodesMap<AnyPcbNode>) {
        return Boolean(Object.values(pcbLayoutNodesMap).find((node) => node.type === PcbNodeTypes.element));
    }

    private static getElementNodes(pcbLayoutNodesMap: PcbNodesMap<AnyPcbBakedNode>) {
        const pcbLayoutNodes = Object.values(pcbLayoutNodesMap);
        return PcbNodeHelper.filterNodesOfType(pcbLayoutNodes, PcbNodeTypes.element).filter((node) =>
            isDescendantOfLayout(pcbLayoutNodesMap, node.uid),
        );
    }

    public getFile(versionShortCode?: string) {
        const options = this.getCsvExportOptions(versionShortCode);
        const csvExporter = new ExportToCsv(options);

        const data = this.createPickAndPlaceData();
        if (data.length > 0) {
            return csvExporter.generateCsv(data, true);
        }
    }

    public download() {
        const versionShortCode = this.documentData.latestActionRecordUid
            ? getDisplayed5DigitChangeId(this.documentData.latestActionRecordUid)
            : undefined;
        const options = this.getCsvExportOptions(versionShortCode);
        const csvExporter = new ExportToCsv(options);

        const data = this.createPickAndPlaceData();

        const zip = new JSZip();
        const zipFolder = zip.folder(options.filename)!;

        for (const formatName of Object.keys(Formats)) {
            zipFolder.file(
                `${options.filename}-${formatName}.csv`,
                csvExporter.generateCsv(this.translateFormat(data, formatName), true),
            );
        }

        zip.generateAsync({type: "blob"}).then((content) => {
            fileDownload(content, `${options.filename}.zip`);
        });
    }

    // Specs via https://support.jlcpcb.com/article/79-pick-place-file-for-smt-assembly
    public createPickAndPlaceData() {
        const data: IDataRow[] = [];

        const elementNodes = PickAndPlaceExporter.getElementNodes(this.pcbLayoutNodes);

        elementNodes.forEach((elementNode) => {
            if (PcbNodeHelper.isNodeExclusiveSubLayoutContainer(elementNode, this.pcbLayoutNodes)) {
                // We don't want any containers in the pick-and-place file and
                // unfortunately the sublayout "root" is of type element
                return;
            }

            const element = ElementHelper.getNestedElementData(this.elements, elementNode.uid);
            if (!element || ElementHelper.isExcludedFromBOM(element)) {
                // NOTE: elements can have exclude_from_pcb, so be absent from the nodes
                // QUESTION: are there other valid reasons to be absent?
                return;
            }

            const {position, rotation} = this.getFootprintCentroidPosition(elementNode);
            const {designator, layer} = this.getFootprintParentElementFields(elementNode);
            const valueProperty = ElementHelper.getDefaultValueProperty(element.properties ?? {});
            const value = valueProperty ? stringifyPropertyValue(valueProperty) : "";
            const properties = element.properties ?? {};

            let packageCode: string;
            if (areOldProperties(properties)) {
                const value = properties[defaultPropertyDefinitions.package_case_code.key];
                packageCode = typeof value === "string" ? value : "";
            } else {
                const rawPackageCode =
                    getPropertyValue(defaultPropertyDefinitions.package_case_code.label, properties) ??
                    getPropertyValue("Package", properties);
                packageCode = typeof rawPackageCode === "string" ? rawPackageCode : "";
            }

            const dataRow: IDataRow = {
                Designator: designator || "",
                "Mid X": `${roundTo(metersToMm(position.x), 4)}mm`,
                "Mid Y": `${roundTo(metersToMm(position.y), 4)}mm`,
                Layer: layer === LayerOrientation.top ? "Top" : "Bottom",
                Rotation: fixAngleDecimals(normalizeDegreeOrientation(radiansToDegrees(rotation))),
                Package: packageCode,
                Value: value as string,
            };

            data.push(dataRow);
        });

        return data.sort(naturalSortByProperty("Designator"));
    }

    private translateFormat(data: IDataRow[], formatName: string) {
        const format = Formats[formatName as keyof typeof Formats];
        return data.map((row) =>
            mapKeys(pick(row, Object.keys(format.columns)), (v, k) => format.columns[k as keyof typeof format.columns]),
        );
    }

    private getCsvExportOptions(versionShortCode: string | undefined) {
        const options = {
            filename: this.createFileName(
                undefined,
                // careful with what characters you use here. PCB manufacturers are from the 70s!
                // we know for sure that they don't allow punctuation marks and &.
                // dash(-) and underscore(_) are ok
                versionShortCode ? `Pick and Place - Version ${versionShortCode}` : "Pick and Place",
            ),
            fieldSeparator: ",",
            decimalSeparator: ".",
            quoteStrings: '"',
            showLabels: true,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
        };
        return options;
    }

    private getFootprintParentElementFields(elementNode: PcbBakedNode<PcbNodeTypes.element>): {
        designator: string | undefined;
        layer: string | undefined;
    } {
        if (!elementNode.bakedRules) {
            return {designator: undefined, layer: undefined};
        }

        return {designator: elementNode.name, layer: elementNode.bakedRules.layer};
    }

    private getFootprintCentroidPosition(elementNode: PcbBakedNode<PcbNodeTypes.element>): {
        position: IVector2;
        rotation: number;
    } {
        if (!elementNode.bakedRules) {
            throw new Error("footprintNode must have bakedRules");
        }

        const elementPads = getDescendantsOfType(this.pcbLayoutNodes, elementNode, PcbNodeTypes.pad);

        const elementRotation = vector3FromXYZ(
            elementNode.bakedRules.rotationRelativeToRootLayout ?? {x: 0, y: 0, z: 0},
        );
        const elementPosition = vector3FromXYZ(
            elementNode.bakedRules.positionRelativeToRootLayout ?? {x: 0, y: 0, z: 0},
        );
        // Get pad positions relative to the element's origin. This would often be equal to pad.bakedRules.position,
        // but not necessarily, because the pad might have repositioned parents within element.
        const padPositions = elementPads
            .filter((p) => p.bakedRules?.positionRelativeToRootLayout)
            .map((p) =>
                vector3FromXYZ(p.bakedRules!.positionRelativeToRootLayout!)
                    .sub(elementPosition)
                    .applyEuler(invertEuler(eulerFromXYZ(elementRotation))),
            );

        const bounds = new Box3().setFromPoints(padPositions);
        // Note, this is not the polygon centroid or the centroid of the points (i.e. average position);
        // it's the rectangular bounding box center. This appears to be what JLCPCB expects,
        // and is similar to Altium's method here:
        // https://www.altium.com/documentation/knowledge-base/altium-designer/calculate-component-pick-and-place-center-of-a-footprint
        const centroid = bounds.getCenter(new Vector3());
        centroid.applyEuler(eulerFromXYZ(elementRotation)).add(elementPosition);

        let zRotation = elementRotation.z;
        // rotationRelativeToRootLayout can end up with either the x or the y axis flipped,
        // even if we only flipped around the y axis in the code, because the inverse world-space
        // transformation has multiple possible answers (eg y=180,z=45 is equivalent to x=180,z=135).
        // So we check both.
        const normX = normalizeRadians(elementRotation.x, true);
        const xFlip = Math.abs(normX) > Math.PI / 2;
        if (xFlip) {
            zRotation = zRotation - Math.PI;
        }

        // Old JLCPCB treated bottom-layer rotation as the negative of Flux's value. However, this
        // switched polarity (!). So now the rotation is the same as Flux's absolute value.
        // We implemented the old behaviour in https://github.com/buildwithflux/flux-app/pull/2079
        // but changed to the new behaviour in Oct 2023.
        //
        // This change was noted by JLCKicadTools in 2022 July here:
        // https://github.com/matthewlai/JLCKicadTools/blob/master/jlc_kicad_tools/jlc_lib/cpl_fix_rotations.py#L166
        //
        // The new behaviour seems to match what's documented elsewhere, for example:
        // http://numerical-help-guide.s3.amazonaws.com/understanding-the-centroid-file-r2-2.pdf
        //
        // Old behaviour would require the following line here to invert bottom-layer rotation:
        // if (xFlip !== yFlip) zRotation *= -1;

        return {
            position: {x: centroid.x, y: centroid.y},
            rotation: zRotation,
        };
    }
}
