// Utility to create a memoized selector
import {IChatMessage} from "@buildwithflux/models";
import {createSelector} from "reselect";

import {createThreadMessagesSelector} from "./createThreadMessagesSelector";

// Selector to get sorted thread messages, reusing the memoized thread messages
export const createSortedThreadMessagesSelector = (threadUid: string) => {
    const threadMessagesSelector = createThreadMessagesSelector(threadUid);

    return createSelector([threadMessagesSelector], (messages): IChatMessage[] => {
        if (!messages) {
            return [];
        }

        const repliesMap: Record<string, IChatMessage[]> = {};
        const latestMessagesMap: Record<string, IChatMessage> = {};

        // Populate latestMessagesMap
        for (const message of messages) {
            const existing = latestMessagesMap[message.reply_to_message_uid];
            if (!existing || new Date(message.updated_at).getTime() > new Date(existing.updated_at).getTime()) {
                latestMessagesMap[message.reply_to_message_uid] = message;
            }
        }

        // Populate repliesMap and rootMessages
        const rootMessages = [];
        for (const message of Object.values(latestMessagesMap)) {
            if (message.reply_to_message_uid === "root") {
                rootMessages.push(message);
            } else {
                if (!repliesMap[message.reply_to_message_uid]) {
                    repliesMap[message.reply_to_message_uid] = [];
                }
                repliesMap[message.reply_to_message_uid]?.push(message);
            }
        }

        // Sort messages recursively
        const sortMessages = (messages: IChatMessage[]): IChatMessage[] => {
            for (const key in repliesMap) {
                repliesMap[key]?.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
            }

            const sorted = [];
            const stack = [
                ...messages.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()),
            ];

            while (stack.length) {
                const current = stack.pop()!;
                sorted.push(current);

                const replies = repliesMap[current.uid];
                if (replies) {
                    stack.push(...replies.reverse());
                }
            }

            return sorted;
        };

        return sortMessages(rootMessages);
    });
};
