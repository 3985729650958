import {EditorModes, PcbNodeTypes} from "@buildwithflux/core";

import type {IDrcInputs, IDrcValidator} from "../types";

export class FloatingCopperValidator implements IDrcValidator {
    problemTypeKey = "floating_copper";
    problemLabel = "Floating Copper";
    problemDescription = "Detect any via, trace or copper fill island that is not connected to any net.";

    checkForProblems({pcbLayoutNodes, pcbConnectivityGraph}: IDrcInputs) {
        const connectedComponents = pcbConnectivityGraph.getConnectedComponents();
        if (!connectedComponents) {
            // TODO: We should probably return a loading state here
            return {error: false as const, problemTypeKey: this.problemTypeKey, foundProblems: []};
        }

        const connectedComponentsWithProblems =
            connectedComponents
                .map((cc) =>
                    cc.map((graphNodeUid) => {
                        const attributes = pcbConnectivityGraph.getNodeAttributes(graphNodeUid);
                        const nodeUid =
                            attributes && "pcbNodeUid" in attributes ? attributes?.pcbNodeUid : graphNodeUid;
                        const nodeType = pcbLayoutNodes[nodeUid]?.type;

                        return {nodeUid, nodeType, graphNodeUid, attributes};
                    }),
                )
                .filter((cc) => {
                    // We don't consider isolated pads as floating copper
                    const isConnected = cc.some((node) => node.nodeType === PcbNodeTypes.pad);
                    return !isConnected;
                }) ?? [];

        return {
            error: false as const,
            problemTypeKey: this.problemTypeKey,
            foundProblems: connectedComponentsWithProblems.map((cc) => ({
                problemTypeKey: this.problemTypeKey,
                key: `${this.problemTypeKey}_${cc.map((n) => n.graphNodeUid).join("+")}`,
                affectedItems: cc.map((node) =>
                    node.nodeType === PcbNodeTypes.fill && node.attributes
                        ? {
                              type: "pcbLayoutNode" as const,
                              uid: node.graphNodeUid,
                              itemLabel: "Copper Fill Island",
                              boundingBox: {
                                  minX: node.attributes.minX,
                                  minY: node.attributes.minY,
                                  maxX: node.attributes.maxX,
                                  maxY: node.attributes.maxY,
                              },
                          }
                        : {type: "pcbLayoutNode" as const, uid: node.nodeUid},
                ),
                affectedViews: [EditorModes.pcb],
            })),
        };
    }
}
