import {
    getPaymentPlanCategory,
    isPaidPaymentPlanCategory,
    IUserData,
    IUserPrivateMetadata,
    OnboardingState,
    PaymentPlanCategory,
    PaymentPlanUids,
} from "@buildwithflux/models";
import {areWeTestingWithJest, isDevEnv, PartialExcept} from "@buildwithflux/shared";
import {isEqual} from "lodash";
import {StoreApi, UseBoundStore} from "zustand";
import {devtools} from "zustand/middleware";
import {immer} from "zustand/middleware/immer";
import {createStore} from "zustand/vanilla";

import {createBoundUseStoreHook} from "../../../helpers/zustand";
import {useFluxServices} from "../../../injection/hooks";
import {AnalyticsStorage} from "../../storage_engine/AnalyticsStorage";
import {UserStorage} from "../../storage_engine/UserStorage";
import {CurrentUserService} from "../types";

/**
 * API of this store - somewhat equivalent to actions, these are the only ways the store state can be modified from outside
 */
type UserStoreApi = {
    setUserData: (userData: PartialExcept<IUserData, "handle">) => Promise<void>;
    setPrivateMetadata: (privateMetadata: Partial<IUserPrivateMetadata>) => Promise<void>;
};

/**
 * The internal data state of this store
 */
type UserStoreData = UserStoreApi & {
    /**
     * Always present, but may be undefined if the user has not been loaded yet
     */
    userData: IUserData | undefined;

    /**
     * Always present, but may be undefined if the private metadata has not been loaded yet
     */
    privateMetadata: IUserPrivateMetadata | undefined;

    /**
     * @deprecated Should be replaced by plans and entitlements
     */
    hasActiveSubscription: boolean | undefined;
};

type UserStoreState = UserStoreApi & UserStoreData;

/**
 * The exported type of the store
 */
export type UseUserStore = UseBoundStore<StoreApi<UserStoreState>>;

/**
 * @deprecated We need to check more than subscriptionStatus - this whole piece of state should be replaced by plans and
 *             entitlements
 */
function getHasActiveSubscription(privateMetadata: IUserPrivateMetadata | undefined, user: IUserData | undefined) {
    if (user?.activeProductUid === PaymentPlanUids.userLegacyEdu) {
        return true;
    }

    return (
        Object.values(privateMetadata?.payment?.subscriptions || {}).filter(
            (subscription) => subscription.subscriptionStatus === "created",
        ).length > 0
    );
}

/**
 * Creates the vanilla non-bound-to-React user store
 *
 * This is a vanilla Zustand store, and is only exported for use in tests. In components, you'd usually use the hooks
 * below, and even when trying to access low-level details of the store, you'd use the bound version of this store,
 * described below.
 *
 * The store returned from this function cannot be directly used as a hook in a component. For that, see
 * `createUserStoreHook` below.
 *
 * QUESTION: how is this different from useCurrentUserStore?
 */
export function createUserStore(
    currentUserService: CurrentUserService,
    analyticsStorage: AnalyticsStorage,
    userStorage: UserStorage,
) {
    return createStore<UserStoreState>()(
        devtools(
            immer((set, get): UserStoreState => {
                async function setPrivateMetadata(newPrivateMetadata: Partial<IUserPrivateMetadata>): Promise<void> {
                    let updatedPrivateMetadata;

                    set(
                        (state) => {
                            if (!state.userData) {
                                throw Error("Requires userData to be loaded");
                            }

                            state.privateMetadata = {
                                ...state.privateMetadata,
                                ...newPrivateMetadata,
                                uid: state.userData.uid,
                            };

                            updatedPrivateMetadata = {
                                ...newPrivateMetadata,
                                uid: state.userData.uid,
                            };

                            state.hasActiveSubscription = getHasActiveSubscription(
                                state.privateMetadata,
                                state.userData,
                            );

                            analyticsStorage.setUser(state.userData);

                            return state;
                        },
                        false,
                        "setPrivateMetadata",
                    );

                    if (updatedPrivateMetadata) {
                        await userStorage.setPrivateUserMetadata(updatedPrivateMetadata);
                    }
                }

                async function setUserData(userData: PartialExcept<IUserData, "handle">): Promise<void> {
                    set(
                        (state) => {
                            if (!state.userData) {
                                throw Error("Requires userData to be set");
                            }

                            state.userData = {
                                ...state.userData,
                                ...userData,
                                uid: state.userData.uid,
                            };

                            state.hasActiveSubscription = getHasActiveSubscription(
                                state.privateMetadata,
                                state.userData,
                            );

                            analyticsStorage.setUser(state.userData);
                        },
                        false,
                        "setUserData",
                    );

                    await userStorage.mergeUser(userData);
                }

                /*
                 * Subscribe to the current user service, and update the store state when the user changes
                 *
                 * TODO unsubscriber is not used, store is effectively a singleton and, if the currentuserService is
                 *   replaced, the store will still be subscribed to the previous one
                 */

                currentUserService.subscribeToUserChanges((change) => {
                    const {userData: existingUser, privateMetadata: existingPrivateMetadata} = get();

                    if (
                        isEqual(existingUser, change.user) &&
                        isEqual(existingPrivateMetadata, change.privateMetadata)
                    ) {
                        return;
                    }

                    set(
                        (state) => {
                            if (!isEqual(existingUser, change.user)) {
                                state.userData = change.user;
                            }

                            if (!isEqual(existingPrivateMetadata, change.privateMetadata)) {
                                state.privateMetadata = change.privateMetadata;
                            }

                            state.hasActiveSubscription = getHasActiveSubscription(change.privateMetadata, change.user);
                        },
                        false,
                        "changeFromSubscription",
                    );
                });

                /*
                 * The current user service will not send subscribe events for an already-settled user: we must grab the initial state ourselves
                 */

                const userData = currentUserService.getCurrentUser();
                const privateMetadata = currentUserService.getCurrentUserPrivateMetadata();
                const hasActiveSubscription = getHasActiveSubscription(privateMetadata, userData);

                return {
                    userData,
                    privateMetadata,
                    hasActiveSubscription,
                    setPrivateMetadata,
                    setUserData,
                };
            }),
            {enabled: isDevEnv() && !areWeTestingWithJest(), name: "UserStore"},
        ),
    );
}

/**
 * Creates the store hook with injected dependencies as a bound store, for use within React
 *
 * This is what we actually attach to the container, but it's easier to test the vanilla store definition above, because
 * it doesn't need any React dependencies
 */
export const createUserStoreHook = (
    currentUserService: CurrentUserService,
    analyticsStorage: AnalyticsStorage,
    userStorage: UserStorage,
): UseUserStore => createBoundUseStoreHook(createUserStore(currentUserService, analyticsStorage, userStorage));

/**
 * Gets a reactive slice of the user store: the private metadata for the current user
 *
 * This is reactive in the sense that if you call this hook from a component, it'll re-execute if the value
 * of that piece of state changes (similar to if you called a useState setter)
 */
export function useUserPrivateMetadata() {
    return useFluxServices().useUserStore((state) => state.privateMetadata);
}

/**
 * @deprecated Should be using plans and entitlements services
 */
export function useUserHasActiveSubscription(): boolean | undefined {
    return useFluxServices().useUserStore((state) => (state.privateMetadata ? state.hasActiveSubscription : undefined));
}

/**
 * @deprecated Should be using plans and entitlements services
 */
export function useUserHasEduActiveSubscription(): boolean {
    return useUserPaymentPlanCategory() === PaymentPlanCategory.enum.userLegacyEdu;
}

/**
 * @deprecated Should be using plans and entitlements services
 *
 * This is buggy in that useUserHasActiveSubscription() can return false during app startup because the userPrivateMetadata
 * isn't finished loading, so uses of this hook have to be guarded against that if they're happening immediately after
 * the user has loaded the page or logged in
 */
export function useUserHasPaidActiveSubscription(): boolean {
    const category = useUserPaymentPlanCategory();
    const active = useUserHasActiveSubscription();
    return !!category && !!active && isPaidPaymentPlanCategory(category);
}

/**
 * @deprecated Should be using plans and entitlements services
 */
export function useUserPaymentPlanCategory(): PaymentPlanCategory | undefined {
    return useFluxServices().useUserStore((state) =>
        state.userData ? getPaymentPlanCategory(state.userData) : undefined,
    );
}

/**
 * State hook for the user's onboarding state.
 * Returns "complete(' if the user has completed onboarding, "incomplete" if they have not, and "unknown" if we don't know yet
 */
export function useUserOnboardingState(): OnboardingState {
    return useFluxServices().useUserStore((state) => {
        if (!state.privateMetadata) {
            return "unknown";
        }

        if (state.privateMetadata.onboarding?.onboarded) {
            return "complete";
        } else {
            return "incomplete";
        }
    });
}

export function useUserViewedAutoLayoutAnnouncementState(): boolean {
    return useFluxServices().useUserStore((state) => Boolean(state.privateMetadata?.announcement?.autoLayoutViewed));
}

export function useUserPrivateProjectCount() {
    return useFluxServices().useUserStore((state) => state.privateMetadata?.projects?.privateProjectCount || 0);
}

/**
 * Returns a function that can be used to persist changes to the user's private metadata for the current user
 */
export function useSetPrivateMetadata() {
    return useFluxServices().useUserStore((state) => state.setPrivateMetadata);
}

/**
 * Returns a function that can be used to persist changes to the user data for the current user
 */
export function useSetUserData() {
    return useFluxServices().useUserStore((state) => state.setUserData);
}
