import type {IApplicationState} from "../../state";

const selectLastPublishedAt = (state: IApplicationState) => state.partVersion?.data?.created_at;
const selectControls = (state: IApplicationState) => state.partVersion?.data?.controls;
const selectPreviewImage = (state: IApplicationState) => state.partVersion?.data?.preview_image;
const selectAssets = (state: IApplicationState) => state.partVersion?.data?.assets;
const selectPartPublishingStatus = (state: IApplicationState) => state.partVersion.publishStatus;

const partVersionDataSelectors = {
    selectControls,
    selectPreviewImage,
    selectAssets,
    selectLastPublishedAt,
    selectPartPublishingStatus,
};

export default partVersionDataSelectors;
