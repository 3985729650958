import {
    ControlPositionData,
    EditorModes,
    IAssetsMap,
    ICommentThreadData,
    IControlConfig,
    IDocumentConfigDataWithoutIds,
    IRouteData,
    IUserData,
    PcbRulesMap,
    SimulationTimeStepSizeUnits,
} from "@buildwithflux/core";
import {
    DocumentPermissionsData,
    IActionRecord,
    IDocumentData,
    IElementData,
    IPartVersionData,
    IProjectInviteData,
    IPropertiesMap,
    ISubDocumentData,
    IVector3,
    IVertexMap,
    IVertexObject,
    MetamoduleAttachmentSides,
    PermissionType,
    UserCodeData,
} from "@buildwithflux/models";
import {createAction} from "@reduxjs/toolkit";
import {Dictionary} from "lodash";

import {SceneManager} from "../../../export";
import {
    createActionRecordAction,
    createHiddenActionRecordAction,
    createSystemAction,
} from "../../../helpers/actionCreator";
import {CurrentUserService} from "../../../modules/auth";
import {RouteUpdate, VertexUpdate} from "../../../modules/schematic_editor/wiring/Wiring";
import {AppThunkAction} from "../../../store";
import {MixedOrSingleProperty} from "../../selectors/document/common/selectors";

export const documentBatchActionTypes = {
    WRITE_DOCUMENT_FINISHED: "WRITE_DOCUMENT_FINISHED",
    WRITE_DOCUMENT_CONFIGS_FINISHED: "WRITE_DOCUMENT_CONFIGS_FINISHED",
};

/**
 * NOTE: This should be used by epics only.
 */
export const writeDocument = createAction(
    "WRITE_DOCUMENT",
    (documentState: IDocumentData, overrideFeatureFlag = false) => {
        return {
            payload: {
                documentState,
                overrideFeatureFlag,
            },
        };
    },
);

// TODO: rename this action somehow to show that is is not handled by withHistoryUpdate,
// and rename it to show that it only works when the slice state is null
export const setDocumentData = createAction("setDocumentData", (documentData: IDocumentData) => {
    return {
        payload: {
            documentData,
        },
    };
});

// TODO: rename this action somehow to show that is is not handled by withHistoryUpdate
export const clearDocumentData = createAction("clearDocumentData");

/**
 * This was created for setting up mock data for tests.
 * TODO: rename this action somehow to show that is is not handled by withHistoryUpdate
 */
export const mergeDocumentData = createAction("mergeDocumentData", (partialDocumentData: Partial<IDocumentData>) => {
    return {
        payload: {
            partialDocumentData,
        },
    };
});

/**
 * This is similar to setDocumentData but it should be handled
 * withHistoryUpdate so it will create a latestPatch.
 */
export const setDocumentDataFromSnapshot = createSystemAction(
    "setDocumentDataFromSnapshot",
    (documentData: IDocumentData) => {
        return {
            payload: {
                documentData,
                canUndo: false,
                updatesDocumentTimestamp: false,
                shouldGenerateActionRecord: false,
            },
        };
    },
);

export const removeSubjectProperties = createActionRecordAction(
    "removeSubjectProperties",
    (subjectType: "elements" | "routes" | "nets", subjectUids: string[], removePropertyKeys: string[]) => {
        return {
            payload: {
                subjectType,
                subjectUids,
                removePropertyKeys,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "removed properties from subject",
);
export const setAssets = createActionRecordAction(
    "setAssets",
    (setAssets: IAssetsMap) => {
        return {
            payload: {
                setAssets,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "added or updated assets",
);
export const removeAssets = createActionRecordAction(
    "removeAssets",
    (removeAssetUids: string[]) => {
        return {
            payload: {
                removeAssetUids,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "removed assets",
);

// QUESTION: this is called by MultiPanel.tsx and UserCodeRuntimeActionMapper.ts...
// shouldn't it generate an action record when dispatched by the user?
export const setNodeLabelMulti = createSystemAction("setNodeLabelMulti", (nodeUidLabelMap: Record<string, string>) => {
    return {
        payload: {
            nodeUidLabelMap,
            canUndo: false,
            updatesDocumentTimestamp: false,
            shouldGenerateActionRecord: false,
            // NOTE: assume the worst
            hasInheritedRules: true,
            hasInheritedCalculations: true,
            requiresDescendantRebake: true,
        },
    };
});

export const setNodeUserData = createSystemAction("setNodeUserData", (nodeUid: string, userData: UserCodeData) => {
    return {
        payload: {
            nodeUid,
            userData,
            canUndo: false,
            updatesDocumentTimestamp: false,
            shouldGenerateActionRecord: false,
        },
    };
});

export const setRulesetUserData = createSystemAction(
    "setRulesetUserData",
    (rulesetUid: string, userData: UserCodeData) => {
        return {
            payload: {
                rulesetUid,
                userData,
                canUndo: false,
                updatesDocumentTimestamp: false,
                shouldGenerateActionRecord: false,
            },
        };
    },
);

export const setElementUserData = createSystemAction(
    "setElementUserData",
    (elementUid: string, userData: UserCodeData) => {
        return {
            payload: {
                elementUid,
                userData,
                canUndo: false,
                updatesDocumentTimestamp: false,
                shouldGenerateActionRecord: false,
            },
        };
    },
);

export const setAssetUserData = createSystemAction("setAssetUserData", (assetUid: string, userData: UserCodeData) => {
    return {
        payload: {
            assetUid,
            userData,
            canUndo: false,
            updatesDocumentTimestamp: false,
            shouldGenerateActionRecord: false,
        },
    };
});

export const setControlUserData = createSystemAction(
    "setControlUserData",
    (controlUid: string, userData: UserCodeData) => {
        return {
            payload: {
                controlUid,
                userData,
                canUndo: false,
                updatesDocumentTimestamp: false,
                shouldGenerateActionRecord: false,
            },
        };
    },
);

export const setNodeRulesMulti = createSystemAction(
    "setNodeRulesMulti",
    (nodeUidRulesMap: Record<string, PcbRulesMap>) => {
        return {
            payload: {
                nodeUidRulesMap,
                canUndo: false,
                updatesDocumentTimestamp: false,
                shouldGenerateActionRecord: false,
                // NOTE: assume the worst
                hasInheritedRules: true,
                hasInheritedCalculations: true,
                requiresDescendantRebake: true,
            },
        };
    },
);

export const setRuleSetSelector = createSystemAction("setRuleSetSelector", (ruleSetUid: string, selector: string) => {
    return {
        payload: {
            ruleSetUid,
            selector,
            canUndo: false,
            updatesDocumentTimestamp: false,
            shouldGenerateActionRecord: false,
        },
    };
});

export const setRuleSetRules = createSystemAction("setRuleSetRules", (ruleSetUid: string, rules: PcbRulesMap) => {
    return {
        payload: {
            ruleSetUid,
            rules,
            canUndo: false,
            updatesDocumentTimestamp: false,
            shouldGenerateActionRecord: false,
        },
    };
});

/**
 * @see renameProject.ts for special action record created in the backend
 */
export const updateName = createActionRecordAction(
    "updateName",
    (newDocumentName: string, newDocumentSlug: string) => {
        return {
            payload: {
                newDocumentName,
                newDocumentSlug,
                // QUESTION: is it correct that we can't undo this action? is it
                // because of the cloud function?
                canUndo: false,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "updated the document name",
);

export const updateDescription = createActionRecordAction(
    "updateDescription",
    (description: string) => {
        return {
            payload: {
                description,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "updated the document description",
);

export const updateSimulationTimeStep = createActionRecordAction(
    "updateSimulationTimeStep",
    (value: number) => {
        return {
            payload: {
                value,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "updated the simulation time step size",
);

export const updateSimulationTimeStepUnit = createActionRecordAction(
    "updateSimulationTimeStepUnit",
    (unit: SimulationTimeStepSizeUnits) => {
        return {
            payload: {
                unit,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "updated the simulation time step unit",
);

export const setDocumentProperties = createActionRecordAction(
    "setDocumentProperties",
    (setProperties: IPropertiesMap) => {
        return {
            payload: {
                setProperties,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "added or updated document properties",
);

export const removeDocumentProperties = createActionRecordAction(
    "removeDocumentProperties",
    (propertyUids: string[]) => {
        return {
            payload: {
                propertyUids,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "removed document properties",
);

export const setControls = createActionRecordAction(
    "setControls",
    (controls: IControlConfig[]) => {
        return {
            payload: {
                controls,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "updated simulation controls",
);

export const selectObjects = createAction(
    "selectObjects",
    (selectedObjectUids: string[], userHasPermission?: boolean) => {
        return {
            payload: {
                selectedObjectUids,
                canUndo: !!userHasPermission,
                updatesDocumentTimestamp: false,
                shouldGenerateActionRecord: false,
            },
        };
    },
);

export function selectObjectsThunk(
    selectedObjectUids: string[],
    userHasPermission?: boolean,
    sceneManager?: SceneManager | null,
): AppThunkAction<void> {
    return (dispatch, _getState, _services) => {
        const related = sceneManager?.getStateManager().getRelatedSubjectsToSelect(selectedObjectUids) ?? [];
        const relatedUids = related.map((r) => r.subjectUid);
        dispatch(selectObjects([...selectedObjectUids, ...relatedUids], userHasPermission));
    };
}

export const updateElementsSchematicPosition = createActionRecordAction(
    "updateElementsSchematicPosition",
    (
        elements: IElementData[],
        wiringUpdates: {vertexUpdates?: VertexUpdate[]} | undefined,
        currentUser: IUserData | undefined,
    ) => {
        return {
            payload: {
                elements,
                currentUser,
                vertexUpdates: wiringUpdates?.vertexUpdates,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "moved schematic elements",
);

export function updateElementsSchematicPositionThunk(
    elements: IElementData[],
    wiringUpdates?: {vertexUpdates?: VertexUpdate[]},
): AppThunkAction<void> {
    return (dispatch, getState, services) => {
        dispatch(
            updateElementsSchematicPosition(elements, wiringUpdates, services.currentUserService.getCurrentUser()),
        );
    };
}

export const lockObjects = createActionRecordAction(
    "lockObjects",
    (objectUids: string[], mode: EditorModes.schematic | EditorModes.pcb, locked: boolean) => {
        return {
            payload: {
                objectUids,
                locked,
                mode,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
                hasInheritedRules: false,
                hasInheritedCalculations: false,
                requiresDescendantRebake: false,
            },
        };
    },
    "locked objects",
);

export const updateElementProperties = createSystemAction(
    "updateElementProperties",
    (elementUid: string, properties: IPropertiesMap) => {
        return {
            payload: {
                elementUid,
                properties,
                canUndo: false,
                updatesDocumentTimestamp: false,
                shouldGenerateActionRecord: false,
            },
        };
    },
);

export const updateRouteProperties = createSystemAction(
    "updateRouteProperties",
    (routeUid: string, properties: IPropertiesMap) => {
        return {
            payload: {
                routeUid,
                properties,
                canUndo: false,
                updatesDocumentTimestamp: false,
                shouldGenerateActionRecord: false,
            },
        };
    },
);

export const updateRoutesLabel = createSystemAction("updateRoutesLabel", (routes: IRouteData[]) => {
    return {
        payload: {
            routes,
            canUndo: false,
            updatesDocumentTimestamp: false,
            shouldGenerateActionRecord: false,
        },
    };
});

export const rotateElements = createActionRecordAction(
    "rotateElements",
    (elements: IElementData[], vertices: IVertexObject[], orientationDelta: number, alongCommonAxis: boolean) => {
        return {
            payload: {
                elements,
                vertices,
                orientationDelta,
                alongCommonAxis,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "rotated elements",
);

export const flipElements = createActionRecordAction(
    "flipElements",
    (elements: IElementData[], routeVertexUpdates?: VertexUpdate[]) => {
        return {
            payload: {
                elements,
                routeVertexUpdates,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "flipped elements",
);

export const updatePlotsPosition = createActionRecordAction(
    "updatePlotsPosition",
    (elementId: string, position: IVector3, attached: MetamoduleAttachmentSides | null) => {
        return {
            payload: {
                elementId,
                position,
                attached,
                shouldGenerateActionRecord: true,
                updatesDocumentTimestamp: true,
                canUndo: true,
            },
        };
    },
    "updated a plot position",
);

export const updatePropertyVisibility = createActionRecordAction(
    "updatePropertyVisibility",
    (elementIds: string[], propertyId: string, visibility: boolean) => {
        return {
            payload: {
                elementIds,
                propertyId,
                visibility,
                shouldGenerateActionRecord: true,
                updatesDocumentTimestamp: true,
                canUndo: true,
            },
        };
    },
    "updated a property's visibility",
);

/**
 * NOTE: Comment thread actions to not work fully with undo/redo or change
 * history, probably because comment contents are handled out-of-band.
 *
 * @see [workplace-post/490325016921235](/docs/workplace/490325016921235.md#490682863552117)
 */
export const createCommentThread = createHiddenActionRecordAction(
    "createCommentThread",
    (commentThread: ICommentThreadData) => {
        return {
            payload: {
                commentThread,
                canUndo: false,
                updatesDocumentTimestamp: false,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "created a comment thread",
);

export const incrementCommentThreadCommentCount = createHiddenActionRecordAction(
    "incrementCommentThreadCommentCount",
    (commentThreadUid: string) => {
        return {
            payload: {
                commentThreadUid,
                canUndo: false,
                updatesDocumentTimestamp: false,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "added to a comment thread",
);

export const pinCommentThread = createHiddenActionRecordAction(
    "pinCommentThread",
    (commentThreadUid: string, pinned: boolean) => {
        return {
            payload: {
                commentThreadUid,
                pinned,
                canUndo: false,
                updatesDocumentTimestamp: false,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "pinned a comment thread",
);

export const resolveCommentThread = createHiddenActionRecordAction(
    "resolveCommentThread",
    (commentThreadUid: string) => {
        return {
            payload: {
                commentThreadUid,
                canUndo: false,
                updatesDocumentTimestamp: false,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "resolved a comment thread",
);

export const unresolveCommentThread = createHiddenActionRecordAction(
    "unresolveCommentThread",
    (commentThreadUid: string) => {
        return {
            payload: {
                commentThreadUid,
                canUndo: false,
                updatesDocumentTimestamp: false,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "unresolved a comment thread",
);

export const deleteCommentThread = createHiddenActionRecordAction(
    "deleteCommentThread",
    (commentThreadUid: string) => {
        return {
            payload: {
                commentThreadUid,
                canUndo: false,
                updatesDocumentTimestamp: false,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "deleted a comment thread",
);

export const updateCommentThreadsPositionAnchor = createHiddenActionRecordAction(
    "updateCommentThreadsPositionAnchor",
    (commentThreads: ICommentThreadData[]) => {
        return {
            payload: {
                comment_threads: commentThreads,
                canUndo: false,
                updatesDocumentTimestamp: false,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "updated a comment thread's position",
);

export const setCode = createActionRecordAction(
    "setCode",
    (code: string, hasErrors: boolean) => {
        return {
            payload: {
                code,
                hasErrors,
                canUndo: false,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    // QUESTION: rename to "updated user code"?
    "updated simulation code",
);

/**
 * NOTE: From Dirk: All sharing and permissions based stuff should exist
 * entirely outside of the user-facing change history and outside of the
 * undo/redo stack. Any sort of roll back should never affect sharing and
 * permissions settings.
 */
export const setRole = createHiddenActionRecordAction(
    "setRole",
    (role: DocumentPermissionsData) => {
        return {
            payload: {
                role,
                canUndo: false,
                updatesDocumentTimestamp: false,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "added a user role",
);

export const setProjectInvite = createHiddenActionRecordAction(
    "setProjectInvite",
    (invite: IProjectInviteData) => {
        return {
            payload: {
                invite,
                canUndo: false,
                updatesDocumentTimestamp: false,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "added a project invite",
);

export const setDocumentAsPrivate = createHiddenActionRecordAction(
    "setDocumentAsPrivate",
    () => {
        return {
            payload: {
                canUndo: false,
                updatesDocumentTimestamp: false,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "set project as private",
);

export const setEnterpriseMemberPermissionType = createHiddenActionRecordAction(
    "setEnterpriseMemberPermissionType",
    (permissionType: PermissionType) => {
        return {
            payload: {
                permissionType,
                canUndo: false,
                updatesDocumentTimestamp: false,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "set enterprise member permission type",
);

export const setOrganizationMemberPermissionType = createHiddenActionRecordAction(
    "setOrganizationMemberPermissionType",
    (permissionType: PermissionType) => {
        return {
            payload: {
                permissionType,
                canUndo: false,
                updatesDocumentTimestamp: false,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "set organization member permission type",
);

export const removeRole = createHiddenActionRecordAction(
    "removeRole",
    (roleUid: string) => {
        return {
            payload: {
                roleUid,
                canUndo: false,
                updatesDocumentTimestamp: false,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "removed a role",
);

export const removeProjectInvite = createHiddenActionRecordAction(
    "removeProjectInvite",
    (invite: IProjectInviteData) => {
        return {
            payload: {
                invite,
                canUndo: false,
                updatesDocumentTimestamp: false,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "removed project invite",
);

/**
 * NOTE: this is used mostly for saving viewport position.
 */
export const setDocumentConfigs = createAction(
    "setDocumentConfigs",
    (configs: IDocumentConfigDataWithoutIds[], currentUser?: IUserData, canUndo?: boolean) => {
        return {
            payload: {
                configs,
                canUndo,
                updatesDocumentTimestamp: false,
                shouldGenerateActionRecord: false,
                currentUser,
            },
        };
    },
);

export const setSimulationControlsValue = createAction(
    "setSimulationControlsValue",
    (elementId: string, controlId: string, value: ControlPositionData, currentUser?: IUserData) => {
        return {
            payload: {
                elementId,
                controlId,
                value,
                canUndo: true,
                updatesDocumentTimestamp: false,
                shouldGenerateActionRecord: false,
                currentUser,
            },
        };
    },
);

export const revertDocumentVersion = createActionRecordAction(
    "revertDocumentVersion",
    (actionRecords: IActionRecord[]) => {
        return {
            payload: {
                actionRecords,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
                // NOTE: assume the worst for baking
                hasInheritedRules: true,
                hasInheritedCalculations: true,
                requiresDescendantRebake: true,
            },
        };
    },
    "reverted the project version",
);

/**
 * NOTE: this is is very similar to setDocumentData but it has different
 * behavior flags so it will create a new action record and trigger a save.
 */
export const restoreDocumentVersion = createActionRecordAction(
    "restoreDocumentVersion",
    (documentData: IDocumentData) => {
        return {
            payload: {
                documentData,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
                // NOTE: assume the worst for baking
                hasInheritedRules: true,
                hasInheritedCalculations: true,
                requiresDescendantRebake: true,
            },
        };
    },
    "restored the project version",
);

export const undoDocument = createActionRecordAction(
    "undoDocument",
    () => {
        return {
            payload: {
                shouldGenerateActionRecord: true,
                updatesDocumentTimestamp: true,
                canUndo: true,
                // NOTE: assume the worst for baking
                hasInheritedRules: true,
                hasInheritedCalculations: true,
                requiresDescendantRebake: true,
            },
        };
    },
    "undid the last change",
);

export const redoDocument = createActionRecordAction(
    "redoDocument",
    () => {
        return {
            payload: {
                shouldGenerateActionRecord: true,
                updatesDocumentTimestamp: true,
                canUndo: true,
                // NOTE: assume the worst for baking
                hasInheritedRules: true,
                hasInheritedCalculations: true,
                requiresDescendantRebake: true,
            },
        };
    },
    "redid the last change",
);

export const deleteSubjects = createActionRecordAction(
    "deleteSubjects",
    (
        subjectUids: string[],
        currentUserService: CurrentUserService,
        vertexUpdates?: VertexUpdate[],
        routeUpdates?: RouteUpdate[],
        alsoDeselectUids: string[] = [],
    ) => {
        return {
            payload: {
                subjectUids,
                alsoDeselectUids,
                vertexUpdates,
                routeUpdates,
                currentUser: currentUserService.getCurrentUser(),
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "deleted subjects",
);

// QUESTION: do we really need this thunk? we can get the currentUser from
// middleware or pass it in
export function deleteSubjectsThunk(
    subjectUids: string[],
    vertexUpdates?: VertexUpdate[],
    routeUpdates?: RouteUpdate[],
    alsoDeselectUids: string[] = [],
): AppThunkAction<void> {
    return (dispatch, getState, services) => {
        dispatch(
            deleteSubjects(subjectUids, services.currentUserService, vertexUpdates, routeUpdates, alsoDeselectUids),
        );
    };
}

export const setSubjectProperties = createActionRecordAction(
    "setSubjectProperties",
    (
        subjectType: "elements" | "routes" | "nets",
        subjectUids: string[],
        setProperties: Dictionary<MixedOrSingleProperty>,
    ) => {
        return {
            payload: {
                subjectType,
                subjectUids,
                setProperties,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "added or updated properties for subject",
);

/**
 * NOTE: this is an exceptional action that can be used as a system action or
 * action record action
 */
export const updateElementsLabel = createAction("updateElementsLabel", (elements: IElementData[], canUndo = true) => {
    return {
        payload: {
            elements,
            canUndo,
            updatesDocumentTimestamp: canUndo,
            shouldGenerateActionRecord: canUndo,
        },
    };
});

export const replaceElementPartVersionDataCache = createActionRecordAction(
    "replaceElementPartVersionDataCache",
    (elements: IElementData[]) => {
        return {
            payload: {
                elements,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "updated the elements part version",
);

/**
 * NOTE: updates to subDocumentData associated with a part version should be
 * handled in an epic that refetches the document snapshots.
 */
export const setPartVersionDataCache = createActionRecordAction(
    "setPartVersionDataCache",
    (partUid: string, partVersionData: IPartVersionData) => {
        return {
            payload: {
                partUid,
                partVersionData,
                canUndo: false,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
                // NOTE: assume the worst
                hasInheritedRules: true,
                hasInheritedCalculations: true,
                requiresDescendantRebake: true,
            },
        };
    },
    "updated a part version",
);

/**
 * NOTE: this action can run when anybody views the doc so we don't want to
 * create an action record. Otherwise it is the same as setPartVersionDataCache.
 */
export const setHeadPartVersionDataCache = createAction(
    "setHeadPartVersionDataCache",
    (partUid: string, partVersionData: IPartVersionData) => {
        return {
            payload: {
                partUid,
                partVersionData,
                canUndo: false,
                updatesDocumentTimestamp: false,
                shouldGenerateActionRecord: false,
                // NOTE: assume the worst
                hasInheritedRules: true,
                hasInheritedCalculations: true,
                requiresDescendantRebake: true,
            },
        };
    },
);

/**
 * Updates the subDocumentData associated with some part versions. Each element
 * of the input array should be a top-level prefixed sub-tree of the global
 * subDocumentData.
 *
 * This action does not generate an action record because it is assumed to
 * happen in response to another action that does such as
 * setPartVersionDataCache. Moreover, subDocumentData is specifically filtered
 * out of action records for size reasons. See patchFilter.
 */
export const mergeSubDocumentData = createAction("mergeSubDocumentData", (subDocumentDatas: ISubDocumentData[]) => {
    return {
        payload: {
            subDocumentDatas,
            canUndo: false,
            updatesDocumentTimestamp: false,
            shouldGenerateActionRecord: false,
            // NOTE: assume the worst
            hasInheritedRules: true,
            hasInheritedCalculations: true,
            requiresDescendantRebake: true,
        },
    };
});

/**
 * Delete the subDocumentData associated with some top-level elements. This does
 * not generate an action record because it is assumed to happen in response to
 * another action.
 */
export const deleteSubDocumentData = createAction("deleteSubDocumentData", (elementUids: string[]) => {
    return {
        payload: {
            elementUids,
            canUndo: false,
            updatesDocumentTimestamp: false,
            shouldGenerateActionRecord: false,
            // NOTE: assume the worst
            hasInheritedRules: true,
            hasInheritedCalculations: true,
            requiresDescendantRebake: true,
        },
    };
});

export const addSubjectsFromClipboard = createActionRecordAction(
    "addSubjectsFromClipboard",
    (elements: IElementData[], routeVertices: IVertexMap, currentUserService: CurrentUserService) => {
        return {
            payload: {
                elements,
                canUndo: true,
                routeVertices,
                currentUser: currentUserService.getCurrentUser(),
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "pasted subjects",
);

// QUESTION: do we really need this thunk? we can get the currentUser from
// middleware or pass it in
export function addSubjectsFromClipboardThunk(
    elements: IElementData[],
    routeVertices: IVertexMap,
): AppThunkAction<void> {
    return (dispatch, getState, services) => {
        dispatch(addSubjectsFromClipboard(elements, routeVertices, services.currentUserService));
    };
}

export const addSubjectsPropertiesFromClipboard = createActionRecordAction(
    "addSubjectsPropertiesFromClipboard",
    (targetElementUids: string[], targetRouteUids: string[], properties: IPropertiesMap) => {
        return {
            payload: {
                targetElementUids,
                targetRouteUids,
                properties,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "pasted properties",
);

export const applyActionRecords = createSystemAction("applyActionRecords", (actionRecords: IActionRecord[]) => {
    return {
        payload: {
            actionRecords,
            canUndo: false,
            updatesDocumentTimestamp: false,
            shouldGenerateActionRecord: false,
            // NOTE: assume the worst for baking
            hasInheritedRules: true,
            hasInheritedCalculations: true,
            requiresDescendantRebake: true,
        },
    };
});

export const revertActionRecords = createSystemAction("revertActionRecords", (actionRecords: IActionRecord[]) => {
    return {
        payload: {
            actionRecords,
            canUndo: false,
            updatesDocumentTimestamp: false,
            shouldGenerateActionRecord: false,
            // NOTE: assume the worst for baking
            hasInheritedRules: true,
            hasInheritedCalculations: true,
            requiresDescendantRebake: true,
        },
    };
});

interface IUpdateWiringParams {
    /**
     * updates of the vertex
     */
    vertexUpdates?: VertexUpdate[];
    /**
     * updates of the routes
     */
    routeUpdates?: RouteUpdate[];
    /**
     * Any objects to select once the updates have been applied
     */
    selectedObjectUids?: string[];
}

/**
 * Update wiring action
 *
 * Note: the system relies on this action being dispatched synchronously, and fails if there's any delay, even if that's
 * just resolving the redux-thunk in the thunk middleware.
 *
 * A symptom of this is WiringControls.test.ts failing on the expected number of segments/vertices.
 *
 * The problematic sequence is:
 *  - A previous WiringControls.continueWiring() call dispatches an updateWiring() action
 *  - When the next sequential WiringControls.continueWiring() calls happens, it calls this.routeManager.placeWire(),
 *     mutating a set of vertices on the routeManager
 *  - Then it calls the routeRenderer's updateSceneForVertices(), which checks the routeManager to see which vertices
 *     are "new", intending to delete any that already exist
 *  - This sees a vertex that already exists as "new", and then fails to delete its old copy, effectively duplicating it.
 *     It's returned to the continueWiring() method as a new segment to add, but the end and start position of this segment
 *     are now the same, because of the duplicated vertex.
 *  - This segment joins a vertex to itself, and should be disallowed, however the updateSceneForVertices() method is
 *     only checking affected vertices, which contains the duplicated vertex just once: i.e. the problem is the _detection_
 *     of whether affected vertices are new, which is a coordination problem between at least three actors (the route manager,
 *     the route renderer, and WiringControls).
 *  - The culprit might actually be the subscriptions to the store on the scene manager, and the public nature of the
 *     vertex map on the routeManager, which is mutated from lots of places. It's hard to say.
 *
 * So, you'll just have to inject any required services here, the manual way. You can't use a redux-thunk version of this
 * action.
 */
export const updateWiring = createActionRecordAction(
    "updateWiring",
    (params: IUpdateWiringParams, currentUserService: CurrentUserService) => {
        const {vertexUpdates, routeUpdates, selectedObjectUids} = params;
        return {
            payload: {
                vertexUpdates,
                routeUpdates,
                // QUESTION: why not just pass in the current user?
                currentUser: currentUserService.getCurrentUser(),
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
                selectedObjectUids,
                // NOTE: assume the worst
                hasInheritedRules: true,
                hasInheritedCalculations: true,
                requiresDescendantRebake: true,
            },
        };
    },
    "added or updated routes",
);

type ConvertSubjectsToNewPartArguments = {
    elements: IElementData[];
    partVersion: IPartVersionData;
    newElement: IElementData;
    vertexUpdates?: VertexUpdate[];
    routeUpdates?: RouteUpdate[];
    currentUser?: IUserData;
};

export const convertSubjectsToNewPart = createActionRecordAction(
    "convertSubjectsToNewPart",
    (args: ConvertSubjectsToNewPartArguments) => {
        const {elements, partVersion, newElement, vertexUpdates, routeUpdates, currentUser} = args;
        return {
            payload: {
                elements,
                partVersion,
                newElement,
                vertexUpdates,
                routeUpdates,
                currentUser,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "converted elements to a new part",
);

// Note: the `createLocalPart` action is only intended for dev use!
export const createLocalPart = createActionRecordAction(
    "createLocalPart",
    (args: {
        element: IElementData;
        partVersion: IPartVersionData;
        newElement: IElementData;
        currentUser?: IUserData;
    }) => {
        const {element, partVersion, newElement, currentUser} = args;
        return {
            payload: {
                element,
                partVersion,
                newElement,
                currentUser,
                canUndo: true,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "create local part from selected element",
);

// NOTE: This action is hidden because we cannot undo it.
export const setBelongsToPartUid = createHiddenActionRecordAction(
    "setBelongsToPartUid",
    (partUid: string) => {
        return {
            payload: {
                partUid,
                canUndo: false,
                updatesDocumentTimestamp: false,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "published a part",
);
