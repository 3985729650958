import {ClipperShape} from "@buildwithflux/core";
import type {IntPoint} from "js-angusj-clipper";
import {BBox} from "rbush";
import {Vector3} from "three";

export enum GraphNodeType {
    routeNodeEndpoint = "routeNodeEndpoint",
    routeNodeLine = "routeNodeLine",
    pad = "pad",
    via = "via",
    fill = "fill",
}

export interface GraphNodeBase extends BBox {
    type: GraphNodeType;
    uid: string;
    netUid: string | undefined;
    connectedLayers: string[] | "All";

    /**
     * We want to exclude some endpoints from routing, and this means not showing airwires to them.
     * This is for example for sublayouts: there are only specific pads the user can route to.
     */
    noRouting?: boolean;

    /**
     * GIANT HACK:
     * Checking fill-pad/via would be very expensive and complicated (non-convex polygon with nested holes).
     * We need to do this because checking the center of the pad is often not enough: if it has an hole no fill would be there!
     * For this reason to check pad/via-fill connectivity we generate several "test points" around the pad/via to perform
     * a sampling-like approximation of collision checking.
     * Test points are in integer Clipper space and in layout-relative coordinates.
     */
    testPoints: IntPoint[];
}

type GraphNodeSpecificAttributesMap = {
    [GraphNodeType.routeNodeEndpoint]: {
        type: GraphNodeType.routeNodeEndpoint;
        pcbNodeUid: string;
        position: Vector3;
    };
    [GraphNodeType.routeNodeLine]: {
        type: GraphNodeType.routeNodeLine;
        pcbNodeUid: string;
        line: [Vector3, Vector3];
    };
    [GraphNodeType.via]: {
        type: GraphNodeType.via;
        pcbNodeUid: string;
        position: Vector3;
    };
    [GraphNodeType.pad]: {
        type: GraphNodeType.pad;
        pcbNodeUid: string;
        position: Vector3;
    };
    [GraphNodeType.fill]: {
        type: GraphNodeType.fill;
        shape: ClipperShape;
        fillId: string;
    };
};
export type GraphNodeAttributes<Type extends GraphNodeType = GraphNodeType> = GraphNodeBase &
    GraphNodeSpecificAttributesMap[Type];

export type UnitNodeAttributes = GraphNodeAttributes<
    GraphNodeType.routeNodeEndpoint | GraphNodeType.routeNodeLine | GraphNodeType.pad | GraphNodeType.via
>;

export type FillNodeAttributes = GraphNodeAttributes<GraphNodeType.fill>;

export type GraphEdgeAttributes = {
    type: "implicit" | "explicit";
    startUid: string;
    endUid: string;
    uid?: string;
};
