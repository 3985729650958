import {
    ApplyPartVersionError,
    IUserData,
    OrganizationMemberWithOrganization,
    PartPublishingStatus,
    PartUpdatingStatus,
    PublishNewPartError,
    PublishNewPartVersionError,
    ReceiveLatestDraftsError,
} from "@buildwithflux/core";
import {IPartVersionData, userCanView} from "@buildwithflux/models";
import {FirebaseError} from "@firebase/util";
import {AnyAction, Dispatch} from "redux";
import {batchActions} from "redux-batched-actions";

import {FluxLogger} from "../../../../../modules/storage_engine/connectors/LogConnector";
import R from "../../../../../resources/Namespace";
import {AppThunkAction} from "../../../../../store";
import {enqueueNotification} from "../../../app/actions";
import {setBelongsToPartUid, setPartVersionDataCache, writeDocument} from "../../../document/actions";
import {setPartIsUpToDate, setPartUpdating} from "../../../part/actions";
import {setPartPublishingStatus, setPartVersionData} from "../../../partVersion/actions";
import {
    emitDocumentPublishedAsPartEvent,
    emitPartUpdatedInProject,
    emitPartVersionPublishedEvent,
    emitReceivingLatestDraftsEvent,
} from "../analytics";

import {startReceivingLatestDraftsForPartUid, stopReceivingLatestDraftsForPartUid} from "./actions";

// NOTE: right now we return boolean as in "success/failure".  We could probably return richer information,
// but it's unclear what we would _do_ with it for now.
export const publishCurrentDocumentAsPart = (): AppThunkAction<Promise<boolean>, AnyAction> => {
    return (dispatch, getState, services) => {
        const state = getState();
        const user = services.currentUserService.getCurrentUser();
        const doc = state.document;
        const docName = state.document?.name;
        if (!user || !doc || !docName) return Promise.resolve(false);
        dispatch(setPartPublishingStatus(PartPublishingStatus.in_progress));
        return services.partLibrary
            .publishToLibrary(doc)
            .then((result) => {
                emitDocumentPublishedAsPartEvent(services.analyticsStorage);
                const {partVersion, part} = result;
                dispatch(
                    batchActions([
                        setPartVersionData(partVersion as IPartVersionData),
                        setPartPublishingStatus(PartPublishingStatus.success),
                        // NOTE: addPartToLibrary also sets belongs_to_part_uid
                        // to be more transactional, but we also want it here
                        // for action records
                        setBelongsToPartUid(part.uid),
                    ]),
                );
                return true;
            })
            .catch((error: any) => {
                FluxLogger.captureError(new PublishNewPartError(doc.uid, error));
                dispatch(setPartPublishingStatus(PartPublishingStatus.failure));
                return false;
            });
    };
};

export const publishNewPartVersionForCurrentDocument = (notes?: string): AppThunkAction<Promise<boolean>> => {
    return (dispatch, getState, services) => {
        const state = getState();
        const user = services.currentUserService.getCurrentUser();
        const doc = state.document;
        const partUid = state.document?.belongs_to_part_uid;
        const docName = state.document?.name;
        if (!user || !doc || !partUid || !docName) return Promise.resolve(false);
        dispatch(setPartPublishingStatus(PartPublishingStatus.in_progress));
        return services.partLibrary
            .publishChangesAsVersion(doc, notes)
            .then((newPartVersion) => {
                const versionNotesCharacterCount = notes?.length ?? 0;
                emitPartVersionPublishedEvent(versionNotesCharacterCount, services.analyticsStorage);
                services.algoliaConnector.clearCache();
                dispatch(
                    batchActions([
                        setPartVersionData(newPartVersion),
                        // QUESTION: how can we suppress this if we want to?
                        // IDEA: we could just create new actions that would discriminate between part _version_ publishing
                        // and part publishing.  Then this could move to an epic.  Alternatively, we could enrich the
                        // setPartPublishingStatus action with a field that distinguishes the two.
                        enqueueNotification(R.strings.part_publish_dialog.publish_success_message, "part", {
                            variant: "success",
                            persist: false,
                            preventDuplicate: true,
                            autoHideDuration: 5000,
                        }),
                        setPartPublishingStatus(PartPublishingStatus.success),
                    ]),
                );
                return true;
            })
            .catch((error: Error) => {
                FluxLogger.captureError(new PublishNewPartVersionError(doc.uid, error));
                setPartPublishingStatus(PartPublishingStatus.failure);
                return false;
            });
    };
};

export const startReceivingDrafts = (partUid: string): AppThunkAction<void> => {
    return (dispatch, getState, {currentUserService, partStorage, analyticsStorage}) => {
        const currentUser = currentUserService.getCurrentUser();

        partStorage
            .getLatestVersion(partUid)
            .then((latestVersion) => {
                // TODO: what happens if this check fails?  What does that even mean?
                if (latestVersion) {
                    const userOwnsPart = currentUser?.uid === latestVersion.owner_uid;
                    emitReceivingLatestDraftsEvent(userOwnsPart, analyticsStorage);
                    // NOTE: LatestDraftsListener should dispatch a
                    // setHeadPartVersionDataCache action as an effect of
                    // updating receiveLatestDraftsForPartUids
                    dispatch(startReceivingLatestDraftsForPartUid(partUid));
                }
            })
            .catch((error) => {
                const docUid = getState().document?.uid;
                FluxLogger.captureError(new ReceiveLatestDraftsError(docUid || "undefined", error, "start"));
            });
    };
};

export const stopReceivingDraftsAndRevertPartVersion = (partUid: string): AppThunkAction<void> => {
    return async (dispatch, getState, {partLibrary}) => {
        const {receiveLatestDraftsForPartUids, uid} = getState().document!;
        let partVersionData = undefined;
        try {
            if (!receiveLatestDraftsForPartUids) {
                throw new Error(`Missing receiveLatestDraftsForPartUids in document ${uid}`);
            }
            const targetVersion = receiveLatestDraftsForPartUids[partUid];
            if (!targetVersion) {
                throw new Error(`Missing partUid ${partUid} in receiveLatestDraftsForPartUids`);
            }
            partVersionData = await partLibrary.get({
                partUid,
                partVersion: targetVersion.revertToVersion,
            });
            if (!partVersionData) {
                throw new Error(`Could not find part version ${targetVersion.revertToVersion} to revert to`);
            }
        } catch (error) {
            FluxLogger.captureError(new ReceiveLatestDraftsError(uid || "undefined", error as Error, "stop attempt"));
        }

        try {
            partVersionData =
                partVersionData ??
                // NOTE: This should never happen but just in case it is better
                // to fallback to the latest published version so the user is
                // not blocked.
                (await partLibrary.get({
                    partUid,
                    partVersion: "latest",
                }));
            if (!partVersionData) {
                throw new Error(`Could not find part version latest to revert to`);
            }
            dispatch(
                batchActions([
                    stopReceivingLatestDraftsForPartUid(partUid),
                    setPartVersionDataCache(partUid, partVersionData),
                ]),
            );
        } catch (error) {
            FluxLogger.captureError(new ReceiveLatestDraftsError(uid || "undefined", error as Error, "stop"));
        }
    };
};

export const fetchAndApplyVersionForPart = (
    partUid: string,
    targetVersion: string,
): AppThunkAction<Promise<boolean>> => {
    return async (dispatch, getState, services) => {
        dispatch(setPartUpdating(partUid, PartUpdatingStatus.in_progress));

        const document = getState().document!;
        try {
            const partVersionData = await services.partLibrary.get({
                partUid,
                partVersion: targetVersion,
            });
            // TODO: what if this doesn't succeed?  Weird to return false.
            const currentUser = services.currentUserService.getCurrentUser();
            const memberships = services.useOrganizationStore.getState().memberships;
            if (
                partVersionData &&
                currentUser &&
                checkPartVersionPermissions(currentUser, partVersionData, memberships, dispatch)
            ) {
                emitPartUpdatedInProject(1, services.analyticsStorage);
                // TODO when a part is updated, we also need to update any wires attached to that part,
                // if any terminals have changed position. However, the `rewireTerminalBoundaryConnections`
                // function to do this currently depends on SceneManager etc which is not (sanely) available
                // here. So for now, wire updating is handled in *ElementSubject.updateTerminalsFromData().
                // Once we've transitioned that logic to be pure-document operations, we can fix this.
                dispatch(
                    batchActions([
                        setPartIsUpToDate(partUid),
                        setPartUpdating(partUid, PartUpdatingStatus.success),
                        setPartVersionDataCache(partUid, partVersionData),
                    ]),
                );

                return true;
            }
            return false;
        } catch (error) {
            FluxLogger.captureError(new ApplyPartVersionError(document.uid ?? "undefined", partUid, targetVersion));
            const actions = getPermissionDeniedActions(error, partUid);
            if (actions.length) {
                dispatch(batchActions(actions));
                return false;
            }
            dispatch(setPartUpdating(partUid, PartUpdatingStatus.failure));
            return false;
        }
    };
};

export const fetchAndApplyVersionsForManyParts = (targets: {
    [partUid: string]: string;
}): AppThunkAction<Promise<void>> => {
    // This is a little different from applying a version for one part because we explicitly want to apply all versions
    // to the store simultaneously as one big batch.
    return async (dispatch, getState, services) => {
        const document = getState().document;
        if (!document) {
            throw new Error("No document found in state. This should never happen.");
        }

        const promises: Promise<{partUid: string; versionData: IPartVersionData | undefined}>[] = Object.entries(
            targets,
        ).map((entry) => {
            const [partUid, targetVersion] = entry;
            dispatch(setPartUpdating(partUid, PartUpdatingStatus.in_progress));
            return services.partLibrary
                .get({partUid, partVersion: targetVersion})
                .then((partVersionData) => {
                    return {partUid: partUid, versionData: partVersionData};
                })
                .catch((_error) => {
                    FluxLogger.captureError(
                        new ApplyPartVersionError(document.uid ?? "undefined", partUid, targetVersion),
                    );
                    return Promise.resolve({partUid: partUid, versionData: undefined});
                });
        });

        const currentUser = services.currentUserService.getCurrentUser();
        const memberships = services.useOrganizationStore.getState().memberships;

        const settleds = await Promise.allSettled(promises);

        const actions = Object.keys(targets).map((partUid, index) => {
            const settled = settleds[index];
            if (!settled) {
                // NOTE: this should never happen
                throw new Error(`Promises must match targets: ${promises.length} and ${targets.length}`);
            }

            const result = settled.status === "fulfilled" ? settled.value : undefined;
            if (result) {
                const {versionData} = result;
                if (versionData && currentUser) {
                    if (checkPartVersionPermissions(currentUser, versionData, memberships, dispatch)) {
                        return [
                            setPartIsUpToDate(partUid),
                            setPartUpdating(partUid, PartUpdatingStatus.success),
                            setPartVersionDataCache(partUid, versionData),
                        ];
                    }
                }
                return [setPartUpdating(partUid, PartUpdatingStatus.failure)];
            }

            const error = settled.status === "rejected" ? settled.reason : undefined;
            if (error) {
                return getPermissionDeniedActions(error, partUid);
            }

            return [];
        });

        dispatch(batchActions(actions.flat()));
    };
};

// TODO: This really doesn't belong here, but here it is for now.
export const writeDocumentToFirebase = (): AppThunkAction<void> => {
    // This is a little different from applying a version for one part because we explicitly want to apply all versions
    // to the store simultaneously as one big batch.
    return (dispatch, getState) => {
        const state = getState();
        if (state.document) {
            const documentData = state.document;
            dispatch(writeDocument(documentData, false));
        }
    };
};

/**
 * Check that the current user has permission to access the latest version data
 * of the part. Notes:
 *
 * - The part data (as opposed to the version data) is permission-less
 * - For proper security, part version access is regulated by firestore.rules
 * - On failure, the part version will be marked as up-to-date, but will revert to out-of-date on the next page reload
 */
function checkPartVersionPermissions(
    currentUser: IUserData,
    partVersionData: IPartVersionData,
    memberships: OrganizationMemberWithOrganization[],
    dispatch: Dispatch<AnyAction>,
) {
    if (
        userCanView(
            currentUser.uid,
            partVersionData.owner_uid,
            partVersionData.roles ?? {},
            partVersionData.organization_owner_uid,
            partVersionData.organization_member_permission_type,
            partVersionData.enterprise_owner_uid,
            partVersionData.enterprise_member_permission_type,
            memberships,
        )
    ) {
        return true;
    }
    dispatch(
        batchActions([
            setPartUpdating(partVersionData.part_uid, PartUpdatingStatus.denied),
            setPartIsUpToDate(partVersionData.part_uid),
        ]),
    );
    return false;
}

function getPermissionDeniedActions(error: any, partUid: string) {
    if (error instanceof FirebaseError && error.code === "permission-denied") {
        return [setPartUpdating(partUid, PartUpdatingStatus.denied), setPartIsUpToDate(partUid)];
    }
    return [];
}
