import {IChatMessage} from "@buildwithflux/models";
import {values} from "lodash";
import {createSelector} from "reselect";

import {ICopilotChatStore} from "../useCopilotChatStore";

// Base selector to filter messages by threadUid and memoize the result
export const createThreadMessagesSelector = (threadUid: string) => {
    return createSelector(
        [(state: ICopilotChatStore) => state.threads[threadUid]],
        (thread): IChatMessage[] | undefined => {
            if (thread) {
                return values(thread.messages);
            }
        },
    );
};
